import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'
import {useDebounce} from 'use-debounce'
import {DEBOUNCE_TIME} from '@common/utils'

import {StyledTextArea} from '../styles/styled'
import {
  ActionContainer,
  ModalContent,
  ModalFooter,
  FacilityContainer,
  TextAreaContainer,
  CharacterCount,
  FacilitiesList,
  FacilityItem,
} from '../styles/shared'

import {Container} from '~/components/general/container/Container'
import {Button} from '~/components/general/button/Button'
import {SideModal} from '~/components/general/modal/SideModal'
import {Typography} from '~/components/general/typography/Typography'
import {Input} from '~/components/general/input/Input'
import {useGetFacilities} from '~/api'
import {usePreventiveActivityAction} from '~/api/practitioner'
import {PreventiveActivity, PreventiveActionType} from '~/types/preventive'
import {Select, Option} from '~/components/general/select/Select'

type GetFacilityDto = {
  id: string
  name: string
}

type ActionSectionProps = {
  activity: PreventiveActivity
  patientId: string
  onActionComplete: () => void
}

const formatActionTypeForDisplay = (action: PreventiveActionType) =>
  action
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

const formatDateToLocalInput = (date: Date) =>
  DateTime.fromJSDate(date).toFormat("yyyy-MM-dd'T'HH:mm")

const parseDatetime = (datetimeString: string) =>
  DateTime.fromFormat(datetimeString, "yyyy-MM-dd'T'HH:mm")
    .toJSDate()
    .toISOString()

export const ActionSection: React.FC<ActionSectionProps> = ({
  activity,
  patientId,
  onActionComplete,
}) => {
  const {t} = useTranslation()
  const [selectedAction, setSelectedAction] = useState<PreventiveActionType>(
    PreventiveActionType.DUE,
  )
  const [selectedFacility, setSelectedFacility] = useState<string>('')
  const [selectedFacilityName, setSelectedFacilityName] = useState<string>('')
  const [facilityQuery, setFacilityQuery] = useState('')
  const [debouncedQuery] = useDebounce(facilityQuery, DEBOUNCE_TIME)
  const [isVisible, setIsVisible] = useState(false)
  const [selectedDate, setSelectedDate] = useState<string>(() => {
    const timeNow = new Date()
    const lastTenMinRounded = new Date(
      timeNow.setMinutes(Math.floor(timeNow.getMinutes() / 10) * 10),
    )
    return formatDateToLocalInput(lastTenMinRounded)
  })
  const [notes, setNotes] = useState('')
  const [results, setResults] = useState('')

  const {data: facilitiesData, isInitialLoading: isLoadingFacilities} =
    useGetFacilities({query: debouncedQuery || undefined})

  const handleFacilitySearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFacilityQuery(e.target.value)
  }

  const handleSelectFacility = (facility: GetFacilityDto) => {
    setSelectedFacility(facility.id)
    setSelectedFacilityName(facility.name)
    setFacilityQuery('')
  }

  const handleRemoveFacility = () => {
    setSelectedFacility('')
    setSelectedFacilityName('')
  }

  const {mutateAsync: submitAction, isLoading} = usePreventiveActivityAction({
    patientId,
    preventiveActivityId: activity.preventiveActivityId,
  })

  const handleConfirm = async () => {
    await submitAction({
      action: selectedAction,
      date: new Date().toISOString(),
      ...(selectedAction === PreventiveActionType.COMPLETED ||
      selectedAction === PreventiveActionType.SCHEDULED
        ? {
            appointmentDate: parseDatetime(selectedDate),
            facilityId: selectedFacility,
            ...(selectedAction === PreventiveActionType.COMPLETED
              ? {
                  notes,
                  results,
                }
              : {}),
          }
        : {}),
    })
    setIsVisible(false)
    onActionComplete()
  }

  const showDatePicker =
    selectedAction === PreventiveActionType.COMPLETED ||
    selectedAction === PreventiveActionType.SCHEDULED

  return (
    <ActionContainer>
      <Select
        value={selectedAction}
        onChange={(e) =>
          setSelectedAction(e.target.value as PreventiveActionType)
        }
        selectedOption={formatActionTypeForDisplay(selectedAction)}>
        {Object.values(PreventiveActionType).map((action) => (
          <Option key={action} onClick={() => setSelectedAction(action)}>
            {formatActionTypeForDisplay(action)}
          </Option>
        ))}
      </Select>
      <Button onClick={() => setIsVisible(true)} disabled={isLoading}>
        {t('Save')}
      </Button>

      <SideModal
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
        header={
          <Typography size="lg" weight="medium">
            {t('Confirm Preventive Activity Action')}
          </Typography>
        }>
        <ModalContent>
          <Container flex direction="column" spacing={2}>
            <Typography>
              {t('Are you sure you want to mark this activity as {{action}}?', {
                action: formatActionTypeForDisplay(selectedAction),
              })}
            </Typography>

            {showDatePicker && (
              <>
                <Container flex direction="column" spacing={2}>
                  <Typography>{t('Date and time')}</Typography>
                  <Input
                    type="datetime-local"
                    value={selectedDate}
                    onChange={(e) => {
                      setSelectedDate(e.target.value)
                    }}
                  />
                </Container>

                <FacilityContainer>
                  <Container flex direction="column" spacing={2}>
                    <Typography>{t('Facility')}</Typography>
                    {selectedFacility ? (
                      <Container
                        flex
                        direction="row"
                        justify="space-between"
                        align="center">
                        <Typography>{selectedFacilityName}</Typography>
                        <Button
                          onClick={handleRemoveFacility}
                          size="sm"
                          color="secondary">
                          {t('Remove')}
                        </Button>
                      </Container>
                    ) : (
                      <>
                        <Input
                          placeholder={t('Search facilities...')}
                          startIcon="Search"
                          onChange={handleFacilitySearch}
                          value={facilityQuery}
                        />
                        {isLoadingFacilities ? (
                          <Typography>{t('Loading...')}</Typography>
                        ) : (
                          <FacilitiesList>
                            {facilitiesData?.pages?.map((page) =>
                              page.records.map((facility: GetFacilityDto) => (
                                <FacilityItem
                                  key={facility.id}
                                  onClick={() =>
                                    handleSelectFacility(facility)
                                  }>
                                  <Typography>{facility.name}</Typography>
                                </FacilityItem>
                              )),
                            )}
                          </FacilitiesList>
                        )}
                      </>
                    )}
                  </Container>
                </FacilityContainer>

                {selectedAction === PreventiveActionType.COMPLETED && (
                  <>
                    <TextAreaContainer>
                      <Typography>{t('Notes')}</Typography>
                      <StyledTextArea
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        maxLength={1000}
                        rows={4}
                        placeholder={t('Enter notes (optional)')}
                      />
                      <CharacterCount>
                        {notes.length}/1000 {t('characters')}
                      </CharacterCount>
                    </TextAreaContainer>

                    <TextAreaContainer>
                      <Typography>{t('Results')}</Typography>
                      <StyledTextArea
                        value={results}
                        onChange={(e) => setResults(e.target.value)}
                        maxLength={1000}
                        rows={4}
                        placeholder={t('Enter results (optional)')}
                      />
                      <CharacterCount>
                        {results.length}/1000 {t('characters')}
                      </CharacterCount>
                    </TextAreaContainer>
                  </>
                )}
              </>
            )}

            <ModalFooter>
              <Container flex justify="flex-end" spacing={2}>
                <Button onClick={() => setIsVisible(false)} color="secondary">
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={handleConfirm}
                  disabled={
                    isLoading ||
                    (showDatePicker && (!selectedDate || !selectedFacility))
                  }>
                  {t('Confirm')}
                </Button>
              </Container>
            </ModalFooter>
          </Container>
        </ModalContent>
      </SideModal>
    </ActionContainer>
  )
}
