import {
  assertArray,
  assertArrayOrNull,
  assertBoolean,
  assertBooleanOrNull,
  assertEnum,
  assertEnumOrNull,
  assertNumber,
  assertNumberOrNull,
  assertObject,
  assertObjectOrNull,
  assertString,
  assertStringOrNull,
  assertValue,
  getAssertEnum,
} from '../utils'
import {
  AnaesthesiaType,
  BloodLossAmount,
  CSectionType,
  EstimatedDeliveryDate,
  EstimatedDeliveryDateSource,
  FeedingPlan,
  HealthJourneyStep,
  HealthJourneyStepCompletion,
  HealthJourneySteps,
  HealthJourneyType,
  LabourPainRelief,
  LabourPosition,
  LabourType,
  LastMenstrualPeriodDate,
  PatientHealthJourney,
  PatientPregnancyBirthPreferences,
  PatientPregnancyFamilyHistory,
  PatientPregnancyFeeling,
  PatientPregnancyIntention,
  PatientJourneyNote,
  PatientJourneyNotes,
  PerinealTrauma,
  PregnancyCSectionEndSummary,
  PregnancyDates,
  PregnancyEndChild,
  PregnancyEndSummary,
  PregnancyEndType,
  PregnancyFamilyHistoryCondition,
  PregnancyFeeling,
  PregnancyIntention,
  PregnancyMiscarriageEndSummary,
  PregnancyOtherEndSummary,
  PregnancyTerminationEndSummary,
  PregnancyVaginalBirthEndSummary,
  Sex,
  VaginalBirthType,
  VitaminKPreference,
  ArthritisJourneyOverview,
  ArthritisSymptoms,
} from '../models'
import {AssertTypeFn} from './general'

export const healthJourneyTypesNormaliser: AssertTypeFn<HealthJourneyType[]> = (
  arr,
) => assertArray(arr, getAssertEnum(HealthJourneyType))

export const healthJourneyStepNormaliser: AssertTypeFn<HealthJourneyStep> = (
  obj,
) => ({
  completion: assertEnum(obj.completion, HealthJourneyStepCompletion),
  isFinal: assertBoolean(obj.isFinal),
  name: assertString(obj.name),
  note: assertStringOrNull(obj.note),
})

export const healthJourneyStepsNormaliser: AssertTypeFn<HealthJourneySteps> = (
  obj,
) => ({
  steps: assertArray(obj.steps, healthJourneyStepNormaliser),
})

export const patientHealthJourneyNormaliser: AssertTypeFn<
  PatientHealthJourney
> = (obj) => ({
  closedAt: assertStringOrNull(obj.closedAt),
  completedSteps: assertArray(obj.completedSteps, healthJourneyStepNormaliser),
  createdAt: assertString(obj.createdAt),
  end: assertStringOrNull(obj.end),
  id: assertString(obj.id),
  setupFinishedAt: assertStringOrNull(obj.setupFinishedAt),
  start: assertStringOrNull(obj.start),
  type: assertEnum(obj.type, HealthJourneyType),
})

export const patientHealthJourneysNormaliser: AssertTypeFn<
  PatientHealthJourney[]
> = (arr) => assertArray(arr, patientHealthJourneyNormaliser)

export const pregnancyDatesNormaliser: AssertTypeFn<PregnancyDates> = (
  obj,
) => ({
  estimatedDeliveryDate: assertString(obj.estimatedDeliveryDate),
  estimatedWeeksInPregnancy: assertNumber(obj.estimatedWeeksInPregnancy),
})

export const pregnancyDatesOrNullNormaliser: AssertTypeFn<
  PregnancyDates | null
> = (obj) => assertObjectOrNull(obj, pregnancyDatesNormaliser)

export const patientPregnancyFeelingNormaliser: AssertTypeFn<
  PatientPregnancyFeeling
> = (obj) => ({
  createdAt: assertString(obj.createdAt),
  feeling: assertEnum(obj.feeling, PregnancyFeeling),
})

export const estimatedDeliveryDateNormaliser: AssertTypeFn<
  EstimatedDeliveryDate
> = (obj) => ({
  date: assertString(obj.date),
  source: assertEnum(obj.source, EstimatedDeliveryDateSource),
  sourceNote: assertStringOrNull(obj.sourceNote),
})

export const estimatedDeliveryDateOrNullNormaliser: AssertTypeFn<
  EstimatedDeliveryDate | null
> = (obj) => assertObjectOrNull(obj, estimatedDeliveryDateNormaliser)

export const lastMenstrualPeriodDateNormaliser: AssertTypeFn<
  LastMenstrualPeriodDate
> = (obj) => ({
  cycleLength: assertNumberOrNull(obj.cycleLength),
  lastPeriodDate: assertString(obj.lastPeriodDate),
})

export const lastMenstrualPeriodDateOrNullNormaliser: AssertTypeFn<
  LastMenstrualPeriodDate | null
> = (obj) => assertObjectOrNull(obj, lastMenstrualPeriodDateNormaliser)

export const patientPregnancyFeelingsNormaliser: AssertTypeFn<
  PatientPregnancyFeeling[]
> = (arr) => assertArray(arr, patientPregnancyFeelingNormaliser)

export const patientPregnancyIntentionNormaliser: AssertTypeFn<
  PatientPregnancyIntention
> = (obj) => ({
  createdAt: assertString(obj.createdAt),
  intention: assertEnum(obj.intention, PregnancyIntention),
})

export const patientPregnancyIntentionsNormaliser: AssertTypeFn<
  PatientPregnancyIntention[]
> = (arr) => assertArray(arr, patientPregnancyIntentionNormaliser)

export const patientPregnancyFamilyHistoryNormaliser: AssertTypeFn<
  PatientPregnancyFamilyHistory
> = (obj) => ({
  conditions: assertArray(
    obj.conditions,
    getAssertEnum(PregnancyFamilyHistoryCondition),
  ),
  recordedAt: assertStringOrNull(obj.recordedAt),
})

export const patientPregnancyBirthPreferencesNormaliser: AssertTypeFn<
  PatientPregnancyBirthPreferences
> = (obj) => ({
  birthPartners: assertArrayOrNull(obj.birthPartners, assertString),
  concerns: assertStringOrNull(obj.concerns),
  coping: assertArrayOrNull(obj.coping, getAssertEnum(LabourPainRelief)),
  createdAt: assertString(obj.createdAt),
  feedingPlan: assertEnumOrNull(obj.feedingPlan, FeedingPlan),
  other: assertStringOrNull(obj.other),
  positions: assertArrayOrNull(obj.positions, getAssertEnum(LabourPosition)),
  skinToSkinContact: assertBooleanOrNull(obj.skinToSkinContact),
  type: assertEnumOrNull(obj.type, LabourType),
  vitaminK: assertEnumOrNull(obj.vitaminK, VitaminKPreference),
})

export const patientPregnancyBirthPreferencesOrNullNormaliser: AssertTypeFn<
  PatientPregnancyBirthPreferences | null
> = (obj) => assertObjectOrNull(obj, patientPregnancyBirthPreferencesNormaliser)

export const patientJourneyNoteNormaliser: AssertTypeFn<PatientJourneyNote> = (
  obj,
) => ({
  content: assertString(obj.content),
  id: assertString(obj.id),
  recordDate: assertString(obj.recordDate),
})

export const patientJourneyNotesNormaliser: AssertTypeFn<
  PatientJourneyNotes
> = (obj) => ({
  notes: assertArray(obj.notes, patientJourneyNoteNormaliser),
})

export const pregnancyEndChildNormaliser: AssertTypeFn<PregnancyEndChild> = (
  obj,
) => ({
  childCondition: assertStringOrNull(obj.childCondition),
  childName: assertStringOrNull(obj.childName),
  isChildWell: assertBooleanOrNull(obj.isChildWell),
  gender: assertEnumOrNull(obj.gender, Sex),
  weightKg: assertNumberOrNull(obj.weightKg),
})

export const cSectionSummaryNormaliser: AssertTypeFn<
  PregnancyCSectionEndSummary
> = (obj) => ({
  anaesthesia: assertArrayOrNull(
    obj.anaesthesia,
    getAssertEnum(AnaesthesiaType),
  ),
  bloodLoss: assertEnumOrNull(obj.bloodLoss, BloodLossAmount),
  children: assertArray(obj.children, pregnancyEndChildNormaliser),
  date: assertString(obj.date),
  details: assertStringOrNull(obj.details),
  endType: assertValue(obj.endType, PregnancyEndType.C_SECTION),
  reasonForType: assertStringOrNull(obj.reasonForType),
  type: assertEnumOrNull(obj.type, CSectionType),
})

export const miscarriageSummaryNormaliser: AssertTypeFn<
  PregnancyMiscarriageEndSummary
> = (obj) => ({
  date: assertString(obj.date),
  details: assertStringOrNull(obj.details),
  endType: assertValue(obj.endType, PregnancyEndType.MISCARRIAGE),
  hideFromPatientLifeMilestones: assertBoolean(
    obj.hideFromPatientLifeMilestones,
  ),
  withSurgery: assertBoolean(obj.withSurgery),
})

export const terminationSummaryNormaliser: AssertTypeFn<
  PregnancyTerminationEndSummary
> = (obj) => ({
  date: assertString(obj.date),
  details: assertStringOrNull(obj.details),
  endType: assertValue(obj.endType, PregnancyEndType.TERMINATION),
  hideFromPatientLifeMilestones: assertBoolean(
    obj.hideFromPatientLifeMilestones,
  ),
  withSurgery: assertBoolean(obj.withSurgery),
})

export const otherPregnancyEndSummaryNormaliser: AssertTypeFn<
  PregnancyOtherEndSummary
> = (obj) => ({
  date: assertString(obj.date),
  details: assertStringOrNull(obj.details),
  endType: assertValue(obj.endType, PregnancyEndType.OTHER),
  hideFromPatientLifeMilestones: assertBoolean(
    obj.hideFromPatientLifeMilestones,
  ),
})

export const vaginalBirthSummaryNormaliser: AssertTypeFn<
  PregnancyVaginalBirthEndSummary
> = (obj) => ({
  anaesthesia: assertArrayOrNull(
    obj.anaesthesia,
    getAssertEnum(LabourPainRelief),
  ),
  bloodLoss: assertEnumOrNull(obj.bloodLoss, BloodLossAmount),
  children: assertArray(obj.children, pregnancyEndChildNormaliser),
  date: assertString(obj.date),
  details: assertStringOrNull(obj.details),
  endType: assertValue(obj.endType, PregnancyEndType.VAGINAL_BIRTH),
  perinealTrauma: assertEnumOrNull(obj.perinealTrauma, PerinealTrauma),
  reasonForType: assertStringOrNull(obj.reasonForType),
  type: assertEnumOrNull(obj.type, VaginalBirthType),
})

export const pregnancyEndSummaryNormaliser: AssertTypeFn<
  PregnancyEndSummary
> = (obj) => {
  switch (obj.endType) {
    case PregnancyEndType.C_SECTION:
      return cSectionSummaryNormaliser(obj)
    case PregnancyEndType.MISCARRIAGE:
      return miscarriageSummaryNormaliser(obj)
    case PregnancyEndType.OTHER:
      return otherPregnancyEndSummaryNormaliser(obj)
    case PregnancyEndType.TERMINATION:
      return terminationSummaryNormaliser(obj)
    case PregnancyEndType.VAGINAL_BIRTH:
      return vaginalBirthSummaryNormaliser(obj)
    default:
      throw new TypeError('Unknown pregnancy end type.')
  }
}

export const pregnancyEndSummaryOrNullNormaliser: AssertTypeFn<
  PregnancyEndSummary | null
> = (obj) => assertObjectOrNull(obj, pregnancyEndSummaryNormaliser)

export const arthritisJourneyOverviewNormaliser: AssertTypeFn<
  ArthritisJourneyOverview
> = (obj) => ({
  diagnosisDate: assertObject(obj.diagnosisDate),
  symptomsStartDate: assertObject(obj.symptomsStartDate),
  diagnosisHistory: assertString(obj.diagnosisHistory),
  personalGoals: assertString(obj.personalGoals),
})

export const arthritisSymptomsNormaliser: AssertTypeFn<ArthritisSymptoms> = (
  obj,
) => ({
  journeyId: assertString(obj.journeyId),
  symptomsDate: assertString(obj.symptomsDate),
  weakness: assertNumber(obj.weakness),
  appetite: assertNumber(obj.appetite),
  fatigue: assertNumber(obj.fatigue),
  irritability: assertNumber(obj.irritability),
  temperature: assertNumber(obj.temperature),
  walkingAndPosture: assertNumber(obj.walkingAndPosture),
})
