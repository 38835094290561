import styled from 'styled-components'

import {Typography} from '~/components/general/typography/Typography'

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
`

export const StyledSection = styled.section`
  margin-bottom: 2rem;
`

export const Title = styled(Typography).attrs({
  variant: 'h2',
  size: 'xl',
  weight: 'bold',
})`
  margin-bottom: 1rem;
  color: ${(props) => props.theme.colors.primary.main};
`

export const Subtitle = styled(Typography).attrs({
  variant: 'h3',
  size: 'lg',
  weight: 'bold',
})`
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.colors.primary.main};
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const SecondaryText = styled(Typography).attrs({
  variant: 'body1',
})`
  color: ${(props) => props.theme.colors.grey.main};
  margin-bottom: 0.5rem;
`

export const StatusLayout = styled.div`
  display: grid;
  grid-template-columns: 250px auto 250px;
  gap: 2rem;
  align-items: start;
  position: relative;
`

export const BodyImageContainer = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  min-height: 800px;
  position: relative;
  margin-top: -4rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 1;
`

export const RightColumn = styled(Column)`
  padding-left: 2rem;
`

export const SectionTitle = styled(Typography).attrs({
  variant: 'h3',
  size: 'lg',
  weight: 'bold',
})`
  color: ${(props) => props.theme.colors.primary.main};
  margin-bottom: 1rem;
`

export const ActivityRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const ActivityStatus = styled.div<{backgroundColor: string}>`
  min-width: 12px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
  background-color: ${(props) => props.backgroundColor};
`

export const ActivityText = styled(Typography).attrs({
  variant: 'body1',
})`
  flex: 1;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.inputText};
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.inputBorder};
  font-family: inherit;
  font-size: inherit;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.primary.main};
  }
`

export const ActivitiesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const CardContent = styled.div`
  padding: 1rem;
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 0 2px 4px ${(props) => props.theme.colors.shadow};
`
