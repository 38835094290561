import ArrowDown from './ArrowDown'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import Baby from './Baby'
import Blood from './Blood'
import BookOpen from './BookOpen'
import Calendar from './Calendar'
import Chart from './Chart'
import Check from './Check'
import CheckboxOff from './CheckboxOff'
import CheckboxOn from './CheckboxOn'
import ChevronDown from './ChevronDown'
import ChevronDownLg from './ChevronDownLg'
import Clinic from './Clinic'
import Clock from './Clock'
import Close from './Close'
import Contact from './Contact'
import Diet from './Diet'
import Distance from './Distance'
import Document from './Document'
import DocumentList from './DocumentList'
import Dots from './Dots'
import Drop from './Drop'
import Edit from './Edit'
import Email from './Email'
import Filter from './Filter'
import FirstAid from './FirstAid'
import Folder from './Folder'
import HDLDrop from './HDLDrop'
import HeartBeat from './HeartBeat'
import HeartCheck from './HeartCheck'
import Home from './Home'
import Image from './Image'
import Logo from './Logo'
import Minus from './Minus'
import Monitoring from './Monitoring'
import MonitoringNote from './MonitoringNote'
import Moon from './Moon'
import Patients from './Patients'
import Person from './Person'
import Pdf from './Pdf'
import Phone from './Phone'
import Pills from './Pills'
import Plaster from './Plaster'
import Plus from './Plus'
import Questionnaires from './Questionnaires'
import Ruler from './Ruler'
import Search from './Search'
import SelectHand from './SelectHand'
import Send from './Send'
import Share from './Share'
import ShareIcon from './ShareIcon'
import Shield from './Shield'
import Smoking from './Smoking'
import Steps from './Steps'
import Surgery from './Surgery'
import Tablet from './Tablet'
import Toast from './Toast'
import TrendDown from './TrendDown'
import TrendUp from './TrendUp'
import TriangleDown from './TriangleDown'
import TriangleUp from './TriangleUp'
import User from './User'
import UserActive from './UserActive'
import UserGroup from './UserGroup'
import UserPercentage from './UserPercentage'
import UserRunning from './UserRunning'
import UsersThree from './UsersThree'
import Vaccination from './Vaccination'
import WaterDrop from './WaterDrop'
import Weight from './Weight'
import World from './World'

export type IconProps = {
  readonly color: string
  readonly size: number
  readonly isFilled?: boolean
}

export const iconNameMap = {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Baby,
  Blood,
  BookOpen,
  Calendar,
  Chart,
  Check,
  CheckboxOff,
  CheckboxOn,
  ChevronDown,
  ChevronDownLg,
  Clinic,
  Clock,
  Close,
  Contact,
  Diet,
  Distance,
  Document,
  DocumentList,
  Dots,
  Drop,
  Edit,
  Email,
  Filter,
  FirstAid,
  Folder,
  HDLDrop,
  HeartBeat,
  HeartCheck,
  Home,
  Image,
  Logo,
  Minus,
  Monitoring,
  MonitoringNote,
  Moon,
  Patients,
  Person,
  Pdf,
  Phone,
  Pills,
  Plaster,
  Plus,
  Questionnaires,
  Ruler,
  Search,
  SelectHand,
  Send,
  Share,
  ShareIcon,
  Shield,
  Smoking,
  Steps,
  Surgery,
  Tablet,
  Toast,
  TrendDown,
  TrendUp,
  TriangleDown,
  TriangleUp,
  User,
  UserActive,
  UserGroup,
  UserPercentage,
  UserRunning,
  UsersThree,
  Vaccination,
  WaterDrop,
  Weight,
  World,
} as const

export const manualRecordIconName = 'SelectHand'
