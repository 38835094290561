import React from 'react'

import {Section} from './Section'
import {
  StatusLayout,
  Column,
  BodyImageContainer,
  RightColumn,
  Title,
  StyledSection,
} from '../styles/styled'

import {PreventiveActivity, BodyLocation} from '~/types/preventive'
import {BodyOutline} from '~/assets/images/BodyOutline'
import {Card} from '~/components/general/card/Card'

type AllActivitiesProps = {
  activities: PreventiveActivity[]
}

export const AllActivities: React.FC<AllActivitiesProps> = ({
  activities: allActivities,
}) => {
  const groupedActivities = allActivities.reduce((acc, activity) => {
    const locationKey = Object.entries(BodyLocation).find(
      ([, value]) => value === activity.bodyLocation,
    )?.[0]

    if (locationKey) {
      if (!acc[locationKey]) {
        acc[locationKey] = []
      }
      acc[locationKey].push(activity)
    }
    return acc
  }, {} as Record<string, PreventiveActivity[]>)

  const headAndNeckActivities = [
    ...(groupedActivities.HEAD || []),
    ...(groupedActivities.NECK || []),
  ]

  return (
    <Card>
      <StyledSection>
        <Title>Current Status</Title>
        <StatusLayout>
          <Column>
            <Section title="Head & Neck" activities={headAndNeckActivities} />
            <Section title="Chest" activities={groupedActivities.CHEST} />
            <Section
              title="Abdominal"
              activities={groupedActivities.ABDOMINAL}
            />
            <Section title="Pelvis" activities={groupedActivities.PELVIS} />
            <Section
              title="Extremities"
              activities={groupedActivities.EXTREMITIES}
            />
          </Column>

          <BodyImageContainer>
            <BodyOutline
              style={{
                width: '300px',
                height: '100%',
                maxHeight: '800px',
                opacity: 0.8,
                position: 'absolute',
                top: '-5rem',
                left: '50%',
                transform: 'translate(-50%, 0) scale(1.6)',
              }}
            />
          </BodyImageContainer>

          <RightColumn>
            <Section
              title="Mental Health"
              activities={groupedActivities.MENTAL_HEALTH}
            />
            <Section title="Blood Tests" activities={groupedActivities.BLOOD} />
            <Section
              title="Lifestyle"
              activities={groupedActivities.LIFESTYLE}
            />
          </RightColumn>
        </StatusLayout>
      </StyledSection>
    </Card>
  )
}
