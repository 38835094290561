import * as React from 'react'

import {IconProps} from '.'

function Email({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5.76904C4.30921 5.76904 3.75 6.32826 3.75 7.01904V16.981C3.75 17.6718 4.30921 18.231 5 18.231H19C19.691 18.231 20.25 17.6716 20.25 16.982V7.01904C20.25 6.32826 19.6908 5.76904 19 5.76904H5ZM19 19.731H5C3.48079 19.731 2.25 18.5003 2.25 16.981V7.01904C2.25 5.49983 3.48079 4.26904 5 4.26904H19C20.5192 4.26904 21.75 5.49983 21.75 7.01904V16.982C21.75 18.5005 20.519 19.731 19 19.731Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35699 8.61424C6.5701 8.25906 7.0308 8.14388 7.38599 8.35699L12.0001 11.1255L16.6142 8.35699C16.9694 8.14388 17.4301 8.25906 17.6432 8.61424C17.8563 8.96943 17.7412 9.43012 17.386 9.64323L12.386 12.6432C12.1485 12.7857 11.8518 12.7857 11.6142 12.6432L6.61424 9.64323C6.25906 9.43012 6.14388 8.96943 6.35699 8.61424Z"
        fill={color}
      />
    </svg>
  )
}

export default Email
