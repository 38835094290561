import React from 'react'
import {format} from 'date-fns'
import {EnumType, useEnumTranslations} from '@common/utils'
import {PractitionerAction} from '@common/models'

import {
  StyledSection,
  Title,
  FlexColumn,
  Subtitle,
  SecondaryText,
} from '../styles/styled'
import {ActionSection} from './ActionSection'

import {Card} from '~/components/general/card/Card'
import {PreventiveActivity} from '~/types/preventive'

type PendingActivitiesProps = {
  activities: PreventiveActivity[]
  patientId: string
  onActionComplete: () => void
}

export const PendingActivities: React.FC<PendingActivitiesProps> = ({
  activities,
  patientId,
  onActionComplete,
}) => {
  const {translateEnumValue} = useEnumTranslations()

  const pendingActivities = activities.filter(
    (activity) =>
      activity.reminderStatus === '0' &&
      activity.practitionerAction !== '1' &&
      activity.practitionerAction !== '3',
  )

  return (
    <Card>
      <StyledSection>
        <Title>Pending Recommendations</Title>
        <FlexColumn>
          {pendingActivities.map((activity) => (
            <Card key={activity.preventiveActivityId}>
              <Subtitle>{activity.description}</Subtitle>
              <SecondaryText>{activity.physicianNotification}</SecondaryText>
              <SecondaryText>
                Due date: {format(new Date(activity.dueDate), 'PP')}
              </SecondaryText>
              <SecondaryText>
                Status:{' '}
                {translateEnumValue(
                  EnumType.PractitionerAction,
                  activity.practitionerAction
                    ? (activity.practitionerAction as PractitionerAction)
                    : PractitionerAction.DUE,
                )}
              </SecondaryText>
              <ActionSection
                activity={activity}
                patientId={patientId}
                onActionComplete={onActionComplete}
              />
            </Card>
          ))}
        </FlexColumn>
      </StyledSection>
    </Card>
  )
}
