import {ContactDetails, CriticalNote} from '@common/models'
import {calculateAgeFromDateOfBirth, formatDate} from '@common/utils'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {DateTime} from 'luxon'

import {Avatar} from '~/components/general/avatar/Avatar'
import {Container} from '~/components/general/container/Container'
import {Typography} from '~/components/general/typography/Typography'
import {theme} from '~/styles/theme'
import {formatName} from '~/utils'
import {Icon} from '~/components/general/icon/Icon'
import {ContactLink} from '~/components/general/contactCard/ContactLink'
import {ContactCard} from '~/components/general/contactCard/ContactCard'

type PatientHeaderProps = {
  patient?: {
    dateOfBirth?: string
    firstName?: string
    secondName?: string
    patientId?: string
  }
  criticalNote?: CriticalNote | null
  contactDetails?: ContactDetails
}

export const PatientHeader: React.FC<PatientHeaderProps> = ({
  criticalNote,
  patient,
  contactDetails,
}) => {
  const {t} = useTranslation()

  const patientName = formatName(patient)
  const criticalNoteContent = criticalNote?.content

  const patientAge = useMemo(
    () => calculateAgeFromDateOfBirth(patient?.dateOfBirth),
    [patient?.dateOfBirth],
  )

  const patientAgeText = t('{{count}} year old (DOB {{dateOfBirth}})', {
    count: patientAge,
    dateOfBirth: formatDate(DateTime.DATE_SHORT, patient?.dateOfBirth),
  })

  return (
    <Container flex align="center" spacing={3}>
      <Avatar size="lg" name={patient} patientId={patient?.patientId} />
      <div>
        <Container flex direction="row" align="center" spacing={2}>
          <Typography size="h3" weight="medium">
            {patientName}
          </Typography>
          {patientAge != null && (
            <Typography color={theme.colors.grey.main} flexShrink={0}>
              {patientAgeText}
            </Typography>
          )}
        </Container>
        <Container flex align="center" spacing={1}>
          <Icon name="MonitoringNote" color={theme.colors.red} />
          <Typography weight="bold" color={theme.colors.red}>
            {criticalNoteContent || t('Key information: ')}
            {!criticalNoteContent && (
              <Typography inline>{t('No details to display')}</Typography>
            )}
          </Typography>
        </Container>
      </div>
      {contactDetails && (
        <ContactCard>
          <ContactLink
            contactType="email"
            contactDetail={contactDetails.email}
          />
          {contactDetails.phoneNumber && (
            <ContactLink
              contactType="phone"
              contactDetail={contactDetails.phoneNumber}
            />
          )}
          {/* <Typography>{contactDetails.email}</Typography>
          <Typography>{contactDetails.phoneNumber}</Typography> */}
        </ContactCard>
      )}
    </Container>
  )
}
