import React, {useContext} from 'react'
import {MISSING_ID} from '@common/utils'
import {useQueryClient} from '@tanstack/react-query'

import {PatientHeader} from '../dashboard/PatientHeader'
import {PatientsContext} from '../PatientsContext'
import {PendingActivities} from './components/PendingActivities'
import {AllActivities} from './components/AllActivities'
import {FlexColumn} from './styles/styled'

import {useGetPatientCriticalNote} from '~/api'
import {useGetPreventiveActivities} from '~/api/practitioner'
import {Loading} from '~/components/general/loading/Loading'
import {Container} from '~/components/general/container/Container'

export const PreventivePage: React.FC = () => {
  const {patient} = useContext(PatientsContext)
  const queryClient = useQueryClient()

  const {data: criticalNote, isInitialLoading: isLoadingNote} =
    useGetPatientCriticalNote({
      patientId: patient?.patientId ?? MISSING_ID,
    })

  const {data: activities, isInitialLoading: isLoadingActivities} =
    useGetPreventiveActivities({
      patientId: patient?.patientId ?? MISSING_ID,
    })

  const handleActionComplete = () => {
    queryClient.invalidateQueries()
  }

  if (!patient?.patientId) {
    return null
  }

  if (isLoadingNote || isLoadingActivities) {
    return <Loading />
  }

  return (
    <Container flex direction="column" spacing={4}>
      <PatientHeader patient={patient} criticalNote={criticalNote} />
      <FlexColumn>
        <PendingActivities
          activities={activities || []}
          patientId={patient.patientId}
          onActionComplete={handleActionComplete}
        />
        <AllActivities activities={activities || []} />
      </FlexColumn>
    </Container>
  )
}
