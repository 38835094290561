import {UseQueryOptions} from '@tanstack/react-query'
import {endpointKeys} from '@common/api'
import {FeatureFlag} from '@common/models'
import {featureFlagsNormaliser} from '@common/normalisers'

import {useApi, useWebGet} from './helpers'

export const useGetFeatureFlags = (
  options: UseQueryOptions<FeatureFlag[], Error>,
) =>
  useWebGet<FeatureFlag[]>(
    [endpointKeys.featureFlags],
    useApi().featureFlagsWeb(),
    featureFlagsNormaliser,
    options,
  )
