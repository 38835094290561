import React from 'react'

import {
  ActivityRow,
  ActivityStatus as StyledStatus,
  ActivityText,
} from '../styles/styled'

import {PreventiveActivity} from '~/types/preventive'
import {Container} from '~/components/general/container/Container'

type ActivityStatusProps = {
  activity: PreventiveActivity
}

export const ActivityStatus: React.FC<ActivityStatusProps> = ({activity}) => {
  const isCompleted =
    activity.practitionerAction === '1' || activity.practitionerAction === '3'

  const getBackgroundColor = () => {
    if (isCompleted) return '#4CAF50'
    if (
      !isCompleted &&
      activity.reminderStatus === '0' &&
      new Date(activity.dueDate) < new Date()
    ) {
      return '#F44336'
    }
    return '#4CAF50'
  }

  return (
    <Container>
      <ActivityRow>
        <StyledStatus backgroundColor={getBackgroundColor()} />
        <ActivityText>{activity.description}</ActivityText>
      </ActivityRow>
    </Container>
  )
}
