import React from 'react'

import {ActivityStatus} from './ActivityStatus'
import {SectionTitle, ActivitiesList} from '../styles/styled'

import {PreventiveActivity} from '~/types/preventive'
import {Container} from '~/components/general/container/Container'

type SectionProps = {
  title: string
  activities?: PreventiveActivity[]
}

export const Section: React.FC<SectionProps> = ({title, activities = []}) => (
  <Container flex direction="column">
    <SectionTitle>{title}</SectionTitle>
    {activities.length > 0 && (
      <ActivitiesList>
        {activities.map((activity) => (
          <ActivityStatus
            key={activity.preventiveActivityId}
            activity={activity}
          />
        ))}
      </ActivitiesList>
    )}
  </Container>
)
