import {endpointKeys} from '@common/api'
import {
  facilityDocumentTypesNormaliser,
  facilityNormaliser,
  getPagedResponseNormaliser,
} from '@common/normalisers'
import {Facility, FacilityDocumentType, PagedResponse} from '@common/models'

import {useWebInfiniteGet, useApi, useWebGet} from './helpers'

export const useGetFacilities = ({query}: {query?: string} = {}) =>
  useWebInfiniteGet<PagedResponse<Facility>>(
    [endpointKeys.facilities, {query}],
    useApi().facilities({query}),
    getPagedResponseNormaliser(facilityNormaliser),
  )

export const useGetFacilityDocumentTypes = () =>
  useWebGet<FacilityDocumentType[]>(
    [endpointKeys.facilityDocumentTypes, {}],
    useApi().facilityDocumentTypes(),
    facilityDocumentTypesNormaliser,
  )
