import {FeatureFlagScope} from '../models'
import {getUrlWithParams} from './searchParams'
import {
  ActiveRecordsUrlParams,
  AllergensUrlParams,
  AppointmentSlotsUrlParams,
  BookmarkMaterialUrlParams,
  ConfirmPatientReportUrlParams,
  DataRequestUrlParams,
  DeviceTokenUrlParam,
  DrugsUrlParams,
  FacilitiesUrlParams,
  FacilityIdParam,
  FileNameUrlParam,
  FileUrlParams,
  FirstResponderFindPatientParams,
  FirstResponderMeasurementsParams,
  FirstResponderSummaryParams,
  HealthRecordsUrlParams,
  IdUrlParam,
  LifeMilestonesUrlParams,
  MeasurementsUrlParams,
  OptionalFacilityIdParam,
  PatientIdUrlParam,
  PractitionerPatientsUrlParams,
  PractitionerPatientUrlParams,
  PractitionerQuestionnairesUrlParams,
  JourneyNoteUrlParams,
  PregnancyWeekByDeliveryDateUrlParams,
  PregnancyWeekByMenstrualPeriodUrlParams,
  QueryUrlParam,
  QuestionnairesUrlParams,
  QuestionnaireUrlParams,
  SettingsUrlParams,
  SlugUrlParam,
  SharingLinkParams,
  SharingLinkMeasurementsParams,
  SplitFacilitiesUrlParams,
  SurveyQuestionnaireUrlParams,
  SurgeryWeekByDateUrlParams,
  TriageQuestionnaireUrlParams,
  PatientSharedMaterialsUrlParams,
  AggregatedActivitiesUrlParams,
  PatientAggregatedActivitiesUrlParams,
  FirstResponderAggregatedActivitiesParams,
  SharingLinkAggregatedActivitiesParams,
  LatestActivitiesUrlParams,
  PatientLatestActivitiesUrlParams,
  FirstResponderLatestActivitiesParams,
  SharingLinkLatestActivitiesParams,
  PregnancyJourneyFileUrlParams,
  PregnancyJourneyEndUrlParams,
  OptionalPatientIdUrlParam,
  LifeMilestonesSummaryUrlParams,
  ObstetricsFileUrlParams,
  PatientJourneyUrlParams,
  AppointmentFileUrlParams,
  AppointmentsUrlParams,
  AppointmentNoteUrlParams,
  PatientCriticalNoteUrlParams,
  PatientAppointmentsUrlParams,
  PatientAppointmentUrlParams,
  PatientAppointmentFileUrlParams,
  PatientHealthJourneyUrlParams,
  PatientTimelineParams,
  CampaignInviteAcceptanceParams,
  AppointmentsForPractitionerParams,
  FacilityIdAppointmentIdParams,
  JourneyTypeUrlParams,
  AddSurgeryDateParams,
  JourneyIdAndDateParams,
} from './types'

export const getApiEndpoints = ({
  apiUrl,
  patientId: apiPatientId = '',
  practitionerId: apiPractitionerId = '',
}: {
  apiUrl: string
  patientId?: string
  practitionerId?: string
}) => {
  const api = {
    acknowledgeFileUpload: ({id}: IdUrlParam) =>
      new URL(
        `patients/${apiPatientId}/file-uploads/${id}/acknowledge`,
        apiUrl,
      ),
    activity: () => new URL(`activity`, apiUrl),
    addPatientForFacility: ({facilityId}: FacilityIdParam) =>
      new URL(`practitioners/employments/${facilityId}/patients`, apiUrl),
    rescheduleAppointmentForFacility: ({
      facilityId,
      appointmentId,
    }: FacilityIdAppointmentIdParams) =>
      new URL(
        `practitioners/employments/${facilityId}/appointments/${appointmentId}`,
        apiUrl,
      ),
    addresses: ({patientId = apiPatientId}: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/addresses`, apiUrl),
    aggregatedActivities: (params: AggregatedActivitiesUrlParams) =>
      getUrlWithParams(apiUrl, `activity/aggregated`, params),
    allergens: (params: AllergensUrlParams) =>
      getUrlWithParams(apiUrl, 'allergens', params),
    apiStatus: () => new URL(`status`, apiUrl),
    appointment: ({id}: IdUrlParam) => new URL(`appointments/${id}`, apiUrl),
    appointments: (params: AppointmentsUrlParams) =>
      getUrlWithParams(apiUrl, 'appointments', params),
    appointmentFacility: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/facility`, apiUrl),
    appointmentFile: ({appointmentId, fileId}: AppointmentFileUrlParams) =>
      new URL(`appointments/${appointmentId}/files/${fileId}`, apiUrl),
    appointmentFiles: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/files`, apiUrl),
    appointmentsForPractitioner: (params: AppointmentsForPractitionerParams) =>
      getUrlWithParams(apiUrl, 'practitioners/appointments', params),
    appointmentHealthJourneyLink: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/journey/link`, apiUrl),
    appointmentNote: ({appointmentId, noteId}: AppointmentNoteUrlParams) =>
      new URL(`appointments/${appointmentId}/note/${noteId}`, apiUrl),
    appointmentNotes: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/note`, apiUrl),
    appointmentSlots: ({
      facilityId,
      appointmentType,
    }: AppointmentSlotsUrlParams) =>
      getUrlWithParams(apiUrl, `facilities/${facilityId}/available-slots`, {
        appointmentType,
      }),
    appointmentSummary: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/summary`, apiUrl),
    appointmentSummaryFile: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/summary/file`, apiUrl),
    appointmentsSummary: () => new URL(`appointments/summary`, apiUrl),
    appointmentSurvey: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/surveys`, apiUrl),
    arthritisJourneyOverview: ({id}: IdUrlParam) =>
      new URL(`patients/journeys/${id}/arthritis-overview`, apiUrl),
    arthritisJourneySymptomsByDate: ({
      healthJourneyId,
      date,
    }: JourneyIdAndDateParams) =>
      new URL(
        `patients/journeys/${healthJourneyId}/arthritis-symptoms/${date}`,
        apiUrl,
      ),
    assignedQuestionnaire: ({
      assignedQuestionnaireId,
    }: QuestionnaireUrlParams) =>
      new URL(`assigned-questionnaire/${assignedQuestionnaireId}`, apiUrl),
    assignedQuestionnaires: (params: QuestionnairesUrlParams) =>
      getUrlWithParams(apiUrl, `assigned-questionnaire`, {
        patientId: apiPatientId,
        ...params,
      }),
    basicHealthInfo: () =>
      new URL(`patients/${apiPatientId}/basic-health-info`, apiUrl),
    bloodPressure: () => new URL(`activity/blood-pressure`, apiUrl),
    bloodType: ({patientId = apiPatientId}: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/blood-type`, apiUrl),
    bodyFatPercentage: () => new URL(`activity/body-fat-percentage`, apiUrl),
    bookableFacilities: ({query}: QueryUrlParam) =>
      getUrlWithParams(apiUrl, 'facilities/bookable', {query}),
    bookmarkMaterial: ({materialId, bookmark}: BookmarkMaterialUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `patients/${apiPatientId}/educational-files/${materialId}/bookmark`,
        {bookmark},
      ),
    cancelAppointment: ({id}: IdUrlParam) =>
      new URL(`appointments/${id}/cancel`, apiUrl),
    clinikoAppointments: () => new URL(`appointments/cliniko`, apiUrl),
    campaignInvites: () => new URL(`subscription/invites/open`, apiUrl),
    campaignSubscriptionAcceptance: ({
      inviteId,
      acceptInvite,
    }: CampaignInviteAcceptanceParams) =>
      getUrlWithParams(apiUrl, `subscription/invites/${inviteId}`, {
        acceptInvite,
      }),
    confirmPatientReport: ({
      uploadId,
      confirm,
    }: ConfirmPatientReportUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `patients/${apiPatientId}/file-uploads/${uploadId}/confirm-patient-report`,
        {confirm},
      ),
    screeningConsultation: ({id: appointmentId}: IdUrlParam) =>
      new URL(`appointments/${appointmentId}/screening-consultation`, apiUrl),
    consultationFile: ({id, patientId = apiPatientId}: FileUrlParams) =>
      new URL(`patients/${patientId}/consultations/${id}/file`, apiUrl),
    contactDetails: ({patientId = apiPatientId}: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/contact-details`, apiUrl),
    createPatientAppointment: ({facilityId}: FacilityIdParam) =>
      new URL(`practitioners/employments/${facilityId}/appointments`, apiUrl),
    criticalNote: () => new URL(`notes/critical`, apiUrl),
    dataRequest: ({
      patientId,
      practitionerId = apiPractitionerId,
    }: DataRequestUrlParams) =>
      new URL(
        `practitioners/${practitionerId}/patients/${patientId}/data-requests`,
        apiUrl,
      ),
    dashboard: () => new URL(`patients/dashboard`, apiUrl),
    deleteMedicalRecord: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/medical-record/${id}`, apiUrl),
    demographicDetails: ({
      patientId = apiPatientId,
    }: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/demographic-details`, apiUrl),
    detailedAssignedQuestionnaires: ({
      facilityId,
      ...params
    }: PractitionerQuestionnairesUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `assigned-questionnaire/facility/${facilityId}/detailed`,
        params,
      ),
    deviceToken: ({token}: DeviceTokenUrlParam) =>
      new URL(`patients/${apiPatientId}/device-tokens/${token}`, apiUrl),
    deviceTokens: () =>
      new URL(`patients/${apiPatientId}/device-tokens`, apiUrl),
    diagnosedCondition: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/diagnosed-conditions/${id}`, apiUrl),
    diagnosedConditions: ({
      patientId = apiPatientId,
      ...params
    }: ActiveRecordsUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `patients/${patientId}/diagnosed-conditions`,
        params,
      ),
    drugs: (params: DrugsUrlParams) =>
      getUrlWithParams(apiUrl, `drugs`, params),
    educationalMaterial: ({id}: IdUrlParam) =>
      new URL(`educational-files/${id}`, apiUrl),
    educationalMaterials: () => new URL(`educational-conditions`, apiUrl),
    facilities: (params: FacilitiesUrlParams) =>
      getUrlWithParams(apiUrl, 'facilities', params),
    facilityDocumentTypes: (params?: FacilityIdParam) =>
      params
        ? new URL(
            `patients/facilities/${params.facilityId}/document-types`,
            apiUrl,
          )
        : new URL(`practitioners/profile/facility-document-types`, apiUrl),
    facilityDocumentTypesForPatient: ({facilityId}: FacilityIdParam) =>
      new URL(
        `patients/${apiPatientId}/facilities/${facilityId}/document-types`,
        apiUrl,
      ),
    facilityPatients: () =>
      new URL(`facilities/patients/${apiPatientId}`, apiUrl),
    featureFlags: () =>
      new URL(`feature-flags/${FeatureFlagScope.MOBILE}`, apiUrl),
    fileUpload: () => new URL(`patients/${apiPatientId}/file-uploads`, apiUrl),
    fileUploadSource: ({id, patientId = apiPatientId}: FileUrlParams) =>
      new URL(
        `patients/${patientId}/file-uploads/${id}/source-document`,
        apiUrl,
      ),
    firstResponderAggregatedActivities: ({
      patientId,
      ...params
    }: FirstResponderAggregatedActivitiesParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/first-responder/patient/${patientId}/activity/aggregated`,
        params,
      ),
    firstResponderAuth: () =>
      new URL(`practitioners/first-responder/validate`, apiUrl),
    firstResponderBasicHealthInfo: ({
      patientId,
      ...params
    }: FirstResponderSummaryParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/first-responder/patient/${patientId}/basic-health-info`,
        params,
      ),
    firstResponderFacilities: ({
      patientId,
      ...params
    }: FirstResponderSummaryParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/first-responder/patient/${patientId}/facilities`,
        params,
      ),
    firstResponderFindPatient: (params: FirstResponderFindPatientParams) =>
      getUrlWithParams(apiUrl, `practitioners/first-responder/patient`, params),
    firstResponderLatestActivities: ({
      patientId,
      ...params
    }: FirstResponderLatestActivitiesParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/first-responder/patient/${patientId}/activity/latest`,
        params,
      ),
    firstResponderMeasurements: ({
      patientId,
      ...params
    }: FirstResponderMeasurementsParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/first-responder/patient/${patientId}/measurements`,
        params,
      ),
    firstResponderDocuments: ({
      patientId,
      ...params
    }: FirstResponderSummaryParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/first-responder/patient/${patientId}/documents`,
        params,
      ),
    getSumsubAccessToken: () =>
      getUrlWithParams(apiUrl, 'sumsub/token', {instanceId: apiPatientId}),
    healthcareNetworkDashboard: () =>
      new URL(`patients/${apiPatientId}/healthcare-network`, apiUrl),
    healthJourney: ({id}: IdUrlParam) => new URL(`journeys/${id}`, apiUrl),
    healthJourneys: () => new URL(`journeys`, apiUrl),
    healthJourneyAppointments: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/appointments`, apiUrl),
    healthJourneySetupSteps: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/setup-steps`, apiUrl),
    healthJourneyTypes: () => new URL(`journeys/types`, apiUrl),
    insuranceDetails: ({patientId = apiPatientId}: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/health-insurance`, apiUrl),
    latestActivities: (params: LatestActivitiesUrlParams) =>
      getUrlWithParams(apiUrl, `activity/latest`, params),
    lifeMilestone: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/life-milestones/${id}`, apiUrl),
    lifeMilestones: ({
      patientId = apiPatientId,
      ...params
    }: LifeMilestonesUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/life-milestones`, params),
    lifeMilestonesSummary: ({
      patientId = apiPatientId,
      includeHidden,
    }: LifeMilestonesSummaryUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `patients/${patientId}/life-milestones/summary`,
        {includeHidden},
      ),
    medicalConditions: ({query}: QueryUrlParam) =>
      getUrlWithParams(apiUrl, `medical-conditions`, {query}),
    medicalRecordFile: ({id, patientId = apiPatientId}: FileUrlParams) =>
      new URL(`patients/${patientId}/medical-record/${id}/file`, apiUrl),
    medicalRecordUpload: () =>
      new URL(`patients/${apiPatientId}/file-uploads/medical`, apiUrl),
    measurements: ({
      patientId = apiPatientId,
      ...params
    }: MeasurementsUrlParams) =>
      getUrlWithParams(apiUrl, `measurements/${patientId}`, params),
    nameDetails: ({patientId = apiPatientId}: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/name-details`, apiUrl),
    nextOfKinDetails: ({patientId = apiPatientId}: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/next-of-kins`, apiUrl),
    notifications: ({id}: IdUrlParam) => new URL(`notifications/${id}`, apiUrl),
    obstetrics: () => new URL(`obstetrics`, apiUrl),
    obstetricsBirthFile: ({id}: IdUrlParam) =>
      new URL(`obstetrics/births/files/${id}`, apiUrl),
    obstetricsPregnancyFile: ({id}: IdUrlParam) =>
      new URL(`obstetrics/terminations/files/${id}`, apiUrl),
    organDonor: ({patientId = apiPatientId}: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/organ-donor`, apiUrl),
    patient: () => new URL(`patients/me`, apiUrl),
    patients: () => new URL('patients/', apiUrl),
    patientAggregatedActivities: ({
      patientId,
      ...params
    }: PatientAggregatedActivitiesUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `patients/${patientId}/activity/aggregated`,
        params,
      ),
    patientAllergy: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/allergies/${id}`, apiUrl),
    patientAllergies: ({
      patientId = apiPatientId,
      ...params
    }: HealthRecordsUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/allergies/`, params),
    patientAppointment: ({
      patientId,
      appointmentId,
    }: PatientAppointmentUrlParams) =>
      new URL(`patients/${patientId}/appointments/${appointmentId}`, apiUrl),
    patientAppointmentSummary: ({
      patientId,
      appointmentId,
    }: PatientAppointmentUrlParams) =>
      new URL(
        `patients/${patientId}/appointments/${appointmentId}/summary`,
        apiUrl,
      ),
    patientAppointmentSummaryFile: ({
      patientId,
      appointmentId,
    }: PatientAppointmentUrlParams) =>
      new URL(
        `patients/${patientId}/appointments/${appointmentId}/summary/file`,
        apiUrl,
      ),
    patientAppointmentFile: ({
      patientId,
      appointmentId,
      fileId,
    }: PatientAppointmentFileUrlParams) =>
      new URL(
        `patients/${patientId}/appointments/${appointmentId}/files/${fileId}`,
        apiUrl,
      ),
    patientAppointments: ({patientId}: PatientAppointmentsUrlParams) =>
      new URL(`patients/${patientId}/appointments`, apiUrl),
    patientAvailableFacilities: (params: QueryUrlParam) =>
      getUrlWithParams(
        apiUrl,
        `patients/${apiPatientId}/facilities-available`,
        params,
      ),
    patientBasicHealthInfo: ({patientId}: PatientIdUrlParam) =>
      new URL(
        `practitioners/${apiPractitionerId}/patients/${patientId}/basic-health-info`,
        apiUrl,
      ),
    patientCholesterol: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/cholesterol/${id}`, apiUrl),
    patientCholesterolRecords: ({
      patientId = apiPatientId,
      ...params
    }: HealthRecordsUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/cholesterol`, params),
    patientCriticalNote: ({patientId, code}: PatientCriticalNoteUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/notes/critical`, {code}),
    patientFacilities: ({query}: QueryUrlParam) =>
      getUrlWithParams(
        apiUrl,
        `patients/${apiPatientId}/facilities-in-network`,
        {query},
      ),
    patientFacility: ({facilityId}: FacilityIdParam) =>
      new URL(`patients/${apiPatientId}/facilities/${facilityId}`, apiUrl),
    patientFacilityDetails: ({facilityId}: FacilityIdParam) =>
      new URL(`patients/facilities/${facilityId}/details`, apiUrl),
    patientFamilyHistory: ({
      patientId = apiPatientId,
    }: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/family-history`, apiUrl),
    patientHealthJourney: ({
      patientId,
      healthJourneyId,
    }: PatientHealthJourneyUrlParams) =>
      new URL(`patients/${patientId}/journeys/${healthJourneyId}`, apiUrl),
    patientHealthJourneys: ({patientId}: PatientIdUrlParam) =>
      new URL(`patients/${patientId}/journeys`, apiUrl),
    patientHealthJourneyAppointments: ({
      patientId,
      healthJourneyId,
    }: PatientJourneyUrlParams) =>
      new URL(
        `patients/${patientId}/journeys/${healthJourneyId}/appointments`,
        apiUrl,
      ),
    patientIdentityDocuments: () =>
      new URL(`patients/${apiPatientId}/identity-documents`, apiUrl),
    patientLatestActivities: ({
      patientId,
      fields,
    }: PatientLatestActivitiesUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/activity/latest`, {
        fields,
      }),
    patientLifestyleInformation: ({
      patientId = apiPatientId,
    }: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/lifestyle`, apiUrl),
    patientMedicalSummary: () =>
      new URL(`patients/${apiPatientId}/medical-summary`, apiUrl),
    patientMedicalTest: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/medical-tests/${id}`, apiUrl),
    patientMedicalTests: ({
      patientId = apiPatientId,
      ...params
    }: HealthRecordsUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/medical-tests`, params),
    patientMedication: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/medications/${id}`, apiUrl),
    patientMedications: ({
      patientId = apiPatientId,
      ...params
    }: ActiveRecordsUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/medications`, params),
    patientNotifications: () => new URL(`patients/notifications`, apiUrl),
    patientObstetrics: ({patientId}: PatientIdUrlParam) =>
      new URL(`patients/${patientId}/obstetrics`, apiUrl),
    patientObstetricsBirthFile: ({
      patientId,
      fileLinkId,
    }: ObstetricsFileUrlParams) =>
      new URL(
        `patients/${patientId}/obstetrics/births/files/${fileLinkId}`,
        apiUrl,
      ),
    patientObstetricsPregnancyFile: ({
      patientId,
      fileLinkId,
    }: ObstetricsFileUrlParams) =>
      new URL(
        `patients/${patientId}/obstetrics/terminations/files/${fileLinkId}`,
        apiUrl,
      ),
    patientOnboardingSteps: () => new URL(`patients/onboarding-steps`, apiUrl),
    patientPersonalInformation: ({
      patientId = apiPatientId,
    }: OptionalPatientIdUrlParam) =>
      new URL(`patients/${patientId}/personal`, apiUrl),
    patientPregnancyJourney: ({
      patientId,
      healthJourneyId,
    }: PatientJourneyUrlParams) =>
      new URL(
        `patients/${patientId}/journeys/${healthJourneyId}/pregnancy`,
        apiUrl,
      ),
    patientProfilePicture: ({patientId}: PatientIdUrlParam) =>
      new URL(`practitioners/patients/${patientId}/profile-picture`, apiUrl),
    patientSharedMaterial: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/educational-files/${id}`, apiUrl),
    patientSharedMaterials: (params: PatientSharedMaterialsUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `patients/${apiPatientId}/educational-files`,
        params,
      ),
    patientSplitFacilities: (params: SplitFacilitiesUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `patients/${apiPatientId}/facilities-split`,
        params,
      ),
    patientSurgery: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/surgeries/${id}`, apiUrl),
    patientSurgeries: ({
      patientId = apiPatientId,
      ...params
    }: HealthRecordsUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/surgeries`, params),
    patientTimeline: ({
      patientId,
      searchTerm,
      typeFilter,
    }: PatientTimelineParams) => {
      const searchParams = new URLSearchParams()
      if (searchTerm) searchParams.append('searchTerm', searchTerm)
      if (typeFilter) searchParams.append('typeFilter', typeFilter)

      return new URL(
        `practitioners/${apiPractitionerId}/patients/${patientId}/health-timeline${
          searchTerm || typeFilter ? `?${searchParams.toString()}` : ''
        }`,
        apiUrl,
      )
    },
    patientUnitSystem: () =>
      new URL(`patients/${apiPatientId}/unit-system`, apiUrl),
    patientVaccination: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/vaccinations/${id}`, apiUrl),
    patientVaccinations: ({
      patientId = apiPatientId,
      ...params
    }: HealthRecordsUrlParams) =>
      getUrlWithParams(apiUrl, `patients/${patientId}/vaccinations`, params),
    practitioner: () => new URL(`practitioners/${apiPractitionerId}`, apiUrl),
    practitionerAssignedQuestionnaires: ({
      facilityId,
      ...params
    }: PractitionerQuestionnairesUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `assigned-questionnaire/facility/${facilityId}`,
        params,
      ),
    physitrackProgram: ({id}: IdUrlParam) =>
      new URL(`physitrack/programs/${id}`, apiUrl),
    physitrackPrograms: ({patientId, page, perPage}: HealthRecordsUrlParams) =>
      getUrlWithParams(apiUrl, `physitrack/programs`, {
        patientId,
        page,
        perPage,
      }),
    practitionerPatient: ({
      facilityId,
      patientId,
    }: PractitionerPatientUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/${apiPractitionerId}/patients/${patientId}`,
        {facilityId},
      ),
    practitionerPatients: (params: PractitionerPatientsUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `practitioners/${apiPractitionerId}/patients`,
        params,
      ),
    journeyArticles: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/articles`, apiUrl),
    pregnancyJourneyBirthPreferences: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/birth-preferences`, apiUrl),
    pregnancyJourneyCSectionEnd: ({
      healthJourneyId,
      closeJourney,
    }: PregnancyJourneyEndUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `journeys/${healthJourneyId}/pregnancy/end/c-section`,
        {closeJourney},
      ),
    pregnancyJourneyDeliveryDate: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/dates`, apiUrl),
    pregnancyJourneyEnd: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/end`, apiUrl),
    pregnancyJourneyEstimatedDeliveryDate: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/dates/delivery-estimate`, apiUrl),
    pregnancyJourneyExercises: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/exercises`, apiUrl),
    pregnancyJourneyFamilyHistory: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/family-history`, apiUrl),
    pregnancyJourneyFeelings: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/feelings`, apiUrl),
    pregnancyJourneyFile: ({
      healthJourneyId,
      fileId,
    }: PregnancyJourneyFileUrlParams) =>
      new URL(`journeys/${healthJourneyId}/pregnancy/files/${fileId}`, apiUrl),
    pregnancyJourneyFiles: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/files`, apiUrl),
    pregnancyJourneyIntentions: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/intentions`, apiUrl),
    pregnancyJourneyMenstrualPeriodDate: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/dates/menstrual-period`, apiUrl),
    pregnancyJourneyMiscarriageEnd: ({
      healthJourneyId,
      closeJourney,
    }: PregnancyJourneyEndUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `journeys/${healthJourneyId}/pregnancy/end/miscarriage`,
        {closeJourney},
      ),
    journeyNote: ({healthJourneyId, noteId}: JourneyNoteUrlParams) =>
      new URL(`journeys/${healthJourneyId}/notes/${noteId}`, apiUrl),
    journeyNotes: ({id}: IdUrlParam) => new URL(`journeys/${id}/notes`, apiUrl),
    journeyViewArticles: ({journeyType}: JourneyTypeUrlParams) =>
      new URL(`journeys/articles/${journeyType}/view-rights`, apiUrl),
    pregnancyJourneyOtherEnd: ({
      healthJourneyId,
      closeJourney,
    }: PregnancyJourneyEndUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `journeys/${healthJourneyId}/pregnancy/end/other`,
        {closeJourney},
      ),
    pregnancyJourneyTerminationEnd: ({
      healthJourneyId,
      closeJourney,
    }: PregnancyJourneyEndUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `journeys/${healthJourneyId}/pregnancy/end/termination`,
        {closeJourney},
      ),
    pregnancyJourneyVaginalBirthEnd: ({
      healthJourneyId,
      closeJourney,
    }: PregnancyJourneyEndUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `journeys/${healthJourneyId}/pregnancy/end/vaginal-birth`,
        {closeJourney},
      ),
    pregnancyJourneyWeekByDeliveryDate: ({
      deliveryDate,
      id,
    }: PregnancyWeekByDeliveryDateUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `journeys/${id}/pregnancy/dates/estimate/by-delivery-date`,
        {deliveryDate},
      ),
    pregnancyJourneyWeekByMenstrualPeriod: ({
      cycleLength,
      id,
      lastPeriodDate,
    }: PregnancyWeekByMenstrualPeriodUrlParams) =>
      getUrlWithParams(
        apiUrl,
        `journeys/${id}/pregnancy/dates/estimate/by-menstrual-period`,
        {cycleLength, lastPeriodDate},
      ),
    questionnaire: ({id}: IdUrlParam) => new URL(`questionnaire/${id}`, apiUrl),
    questionnaires: (params: FacilityIdParam) =>
      getUrlWithParams(apiUrl, 'questionnaire', params),
    requestMedicalRecords: () =>
      new URL(`patients/${apiPatientId}/medical-record/request`, apiUrl),
    restingHeartRate: () => new URL(`activity/resting-heart-rate`, apiUrl),
    setDefaultPatientPage: () =>
      new URL('practitioners/profile/default-patient-page', apiUrl),
    settings: ({type, query}: SettingsUrlParams) =>
      getUrlWithParams(apiUrl, `util/settings/${type}`, {query}),
    shareMaterials: () =>
      new URL(`practitioners/${apiPractitionerId}/educational-files`, apiUrl),
    sharedMaterials: ({patientId}: PatientIdUrlParam) =>
      new URL(
        `practitioners/${apiPractitionerId}/patients/${patientId}/educational-files`,
        apiUrl,
      ),
    sharedMaterialsContents: (params: OptionalFacilityIdParam) =>
      getUrlWithParams(
        apiUrl,
        `patients/${apiPatientId}/educational-conditions`,
        params,
      ),
    sharingLink: ({id}: IdUrlParam) =>
      new URL(`patients/${apiPatientId}/share-links/${id}`, apiUrl),
    sharingLinkAggregatedActivities: (
      params: SharingLinkAggregatedActivitiesParams,
    ) =>
      getUrlWithParams(
        apiUrl,
        'medical-record-share/activity/aggregated',
        params,
      ),
    sharingLinkBasicHealthInfo: (params: SharingLinkParams) =>
      getUrlWithParams(
        apiUrl,
        'medical-record-share/basic-health-info',
        params,
      ),
    sharingLinkCriticalNote: (params: SharingLinkParams) =>
      getUrlWithParams(apiUrl, 'medical-record-share/notes/critical', params),
    sharingLinkLatestActivities: (params: SharingLinkLatestActivitiesParams) =>
      getUrlWithParams(apiUrl, 'medical-record-share/activity/latest', params),
    sharingLinkMeasurements: (params: SharingLinkMeasurementsParams) =>
      getUrlWithParams(apiUrl, 'medical-record-share/measurements', params),
    sharingLinks: () => new URL(`patients/${apiPatientId}/share-links`, apiUrl),
    sharingLinkValidation: (params: SharingLinkParams) =>
      getUrlWithParams(apiUrl, 'medical-record-share/validate', params),
    staticFile: ({fileName}: FileNameUrlParam) =>
      new URL(`static-files/${fileName}`, apiUrl),

    surgeryJourneyEnd: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/pregnancy/end`, apiUrl),
    surgicalJourneyWeekByDate: ({
      surgeryDate,
      id,
    }: SurgeryWeekByDateUrlParams) =>
      getUrlWithParams(apiUrl, `journeys/${id}/surgery/dates/by-date`, {
        surgeryDate,
      }),
    surgeryJourneyDate: ({id, surgeryDate}: AddSurgeryDateParams) =>
      getUrlWithParams(apiUrl, `journeys/${id}/surgery/date`, {surgeryDate}),
    surgicalJourneyExercises: ({id}: IdUrlParam) =>
      new URL(`journeys/${id}/surgical/exercises`, apiUrl),
    surveyQuestionnaire: ({surveyType}: SurveyQuestionnaireUrlParams) =>
      new URL(`questionnaire/survey/${surveyType}`, apiUrl),
    surveyQuestionnaireResponse: ({id}: IdUrlParam) =>
      new URL(`questionnaire/${id}/survey`, apiUrl),
    triageQuestionnaire: (params: TriageQuestionnaireUrlParams) =>
      getUrlWithParams(apiUrl, `questionnaire/triage`, params),
    uploadSingleAppointmentFile: () =>
      new URL(
        `patients/${apiPatientId}/file-uploads/single-appointment-file`,
        apiUrl,
      ),
    vaccinationCertUpload: () =>
      new URL(`patients/${apiPatientId}/file-uploads/vaccination`, apiUrl),
    vaccines: ({query}: QueryUrlParam) =>
      getUrlWithParams(apiUrl, 'vaccines', {query}),
    vitalConfig: () => new URL('activity/sources/vital/sdk-config', apiUrl),
    vitalProviders: () => new URL(`activity/sources/vital/providers`, apiUrl),
    vitalProviderConnection: ({slug}: SlugUrlParam) =>
      new URL(`activity/sources/vital/providers/connection/${slug}`, apiUrl),
    vitalProviderConnections: () =>
      new URL(`activity/sources/vital/providers/connection`, apiUrl),
    waterConsumption: () => new URL(`activity/water-consumption-ml`, apiUrl),
  } as const
  return api
}

export type EndpointKey = keyof ReturnType<typeof getApiEndpoints>

export const endpointKeys: {[key in EndpointKey]: key} = {
  acknowledgeFileUpload: 'acknowledgeFileUpload',
  activity: 'activity',
  addresses: 'addresses',
  addPatientForFacility: 'addPatientForFacility',
  aggregatedActivities: 'aggregatedActivities',
  allergens: 'allergens',
  apiStatus: 'apiStatus',
  appointment: 'appointment',
  appointments: 'appointments',
  appointmentFacility: 'appointmentFacility',
  appointmentFile: 'appointmentFile',
  appointmentFiles: 'appointmentFiles',
  appointmentHealthJourneyLink: 'appointmentHealthJourneyLink',
  appointmentNote: 'appointmentNote',
  appointmentNotes: 'appointmentNotes',
  appointmentSlots: 'appointmentSlots',
  appointmentsForPractitioner: 'appointmentsForPractitioner',
  appointmentSummary: 'appointmentSummary',
  appointmentSummaryFile: 'appointmentSummaryFile',
  appointmentsSummary: 'appointmentsSummary',
  appointmentSurvey: 'appointmentSurvey',
  arthritisJourneyOverview: 'arthritisJourneyOverview',
  arthritisJourneySymptomsByDate: 'arthritisJourneySymptomsByDate',
  assignedQuestionnaire: 'assignedQuestionnaire',
  assignedQuestionnaires: 'assignedQuestionnaires',
  basicHealthInfo: 'basicHealthInfo',
  bloodPressure: 'bloodPressure',
  bloodType: 'bloodType',
  bodyFatPercentage: 'bodyFatPercentage',
  bookableFacilities: 'bookableFacilities',
  bookmarkMaterial: 'bookmarkMaterial',
  cancelAppointment: 'cancelAppointment',
  campaignInvites: 'campaignInvites',
  campaignSubscriptionAcceptance: 'campaignSubscriptionAcceptance',
  clinikoAppointments: 'clinikoAppointments',
  confirmPatientReport: 'confirmPatientReport',
  consultationFile: 'consultationFile',
  contactDetails: 'contactDetails',
  createPatientAppointment: 'createPatientAppointment',
  criticalNote: 'criticalNote',
  dataRequest: 'dataRequest',
  dashboard: 'dashboard',
  deleteMedicalRecord: 'deleteMedicalRecord',
  demographicDetails: 'demographicDetails',
  detailedAssignedQuestionnaires: 'detailedAssignedQuestionnaires',
  deviceToken: 'deviceToken',
  deviceTokens: 'deviceTokens',
  diagnosedCondition: 'diagnosedCondition',
  diagnosedConditions: 'diagnosedConditions',
  drugs: 'drugs',
  educationalMaterial: 'educationalMaterial',
  educationalMaterials: 'educationalMaterials',
  featureFlags: 'featureFlags',
  facilities: 'facilities',
  facilityDocumentTypes: 'facilityDocumentTypes',
  facilityDocumentTypesForPatient: 'facilityDocumentTypesForPatient',
  facilityPatients: 'facilityPatients',
  fileUpload: 'fileUpload',
  fileUploadSource: 'fileUploadSource',
  firstResponderAuth: 'firstResponderAuth',
  firstResponderFindPatient: 'firstResponderFindPatient',
  firstResponderAggregatedActivities: 'firstResponderAggregatedActivities',
  firstResponderBasicHealthInfo: 'firstResponderBasicHealthInfo',
  firstResponderFacilities: 'firstResponderFacilities',
  firstResponderLatestActivities: 'firstResponderLatestActivities',
  firstResponderMeasurements: 'firstResponderMeasurements',
  firstResponderDocuments: 'firstResponderDocuments',
  getSumsubAccessToken: 'getSumsubAccessToken',
  healthcareNetworkDashboard: 'healthcareNetworkDashboard',
  healthJourney: 'healthJourney',
  healthJourneys: 'healthJourneys',
  healthJourneyAppointments: 'healthJourneyAppointments',
  healthJourneySetupSteps: 'healthJourneySetupSteps',
  healthJourneyTypes: 'healthJourneyTypes',
  insuranceDetails: 'insuranceDetails',
  journeyNote: 'journeyNote',
  journeyNotes: 'journeyNotes',
  latestActivities: 'latestActivities',
  lifeMilestone: 'lifeMilestone',
  lifeMilestones: 'lifeMilestones',
  lifeMilestonesSummary: 'lifeMilestonesSummary',
  medicalConditions: 'medicalConditions',
  medicalRecordFile: 'medicalRecordFile',
  medicalRecordUpload: 'medicalRecordUpload',
  measurements: 'measurements',
  nameDetails: 'nameDetails',
  nextOfKinDetails: 'nextOfKinDetails',
  notifications: 'notifications',
  obstetrics: 'obstetrics',
  obstetricsBirthFile: 'obstetricsBirthFile',
  obstetricsPregnancyFile: 'obstetricsPregnancyFile',
  organDonor: 'organDonor',
  patient: 'patient',
  patients: 'patients',
  patientAggregatedActivities: 'patientAggregatedActivities',
  patientAllergy: 'patientAllergy',
  patientAllergies: 'patientAllergies',
  patientAppointment: 'patientAppointment',
  patientAppointmentFile: 'patientAppointmentFile',
  patientAppointmentSummary: 'patientAppointmentSummary',
  patientAppointmentSummaryFile: 'patientAppointmentSummaryFile',
  patientAppointments: 'patientAppointments',
  patientAvailableFacilities: 'patientAvailableFacilities',
  patientBasicHealthInfo: 'patientBasicHealthInfo',
  patientCholesterol: 'patientCholesterol',
  patientCholesterolRecords: 'patientCholesterolRecords',
  patientCriticalNote: 'patientCriticalNote',
  patientFacilities: 'patientFacilities',
  patientFacility: 'patientFacility',
  patientFacilityDetails: 'patientFacilityDetails',
  patientFamilyHistory: 'patientFamilyHistory',
  patientHealthJourney: 'patientHealthJourney',
  patientHealthJourneys: 'patientHealthJourneys',
  patientHealthJourneyAppointments: 'patientHealthJourneyAppointments',
  patientIdentityDocuments: 'patientIdentityDocuments',
  patientLatestActivities: 'patientLatestActivities',
  patientLifestyleInformation: 'patientLifestyleInformation',
  patientMedicalSummary: 'patientMedicalSummary',
  patientMedicalTest: 'patientMedicalTest',
  patientMedicalTests: 'patientMedicalTests',
  patientMedication: 'patientMedication',
  patientMedications: 'patientMedications',
  patientNotifications: 'patientNotifications',
  patientObstetrics: 'patientObstetrics',
  patientObstetricsBirthFile: 'patientObstetricsBirthFile',
  patientObstetricsPregnancyFile: 'patientObstetricsPregnancyFile',
  patientOnboardingSteps: 'patientOnboardingSteps',
  patientPersonalInformation: 'patientPersonalInformation',
  patientPregnancyJourney: 'patientPregnancyJourney',
  patientProfilePicture: 'patientProfilePicture',
  patientSharedMaterial: 'patientSharedMaterial',
  patientSharedMaterials: 'patientSharedMaterials',
  patientSplitFacilities: 'patientSplitFacilities',
  patientSurgery: 'patientSurgery',
  patientSurgeries: 'patientSurgeries',
  patientTimeline: 'patientTimeline',
  patientUnitSystem: 'patientUnitSystem',
  patientVaccination: 'patientVaccination',
  patientVaccinations: 'patientVaccinations',
  physitrackProgram: 'physitrackProgram',
  physitrackPrograms: 'physitrackPrograms',
  practitioner: 'practitioner',
  practitionerAssignedQuestionnaires: 'practitionerAssignedQuestionnaires',
  practitionerPatient: 'practitionerPatient',
  practitionerPatients: 'practitionerPatients',
  journeyArticles: 'journeyArticles',
  journeyViewArticles: 'journeyViewArticles',
  pregnancyJourneyBirthPreferences: 'pregnancyJourneyBirthPreferences',
  pregnancyJourneyCSectionEnd: 'pregnancyJourneyCSectionEnd',
  pregnancyJourneyDeliveryDate: 'pregnancyJourneyDeliveryDate',
  pregnancyJourneyEnd: 'pregnancyJourneyEnd',
  pregnancyJourneyEstimatedDeliveryDate:
    'pregnancyJourneyEstimatedDeliveryDate',
  pregnancyJourneyExercises: 'pregnancyJourneyExercises',
  pregnancyJourneyFamilyHistory: 'pregnancyJourneyFamilyHistory',
  pregnancyJourneyFeelings: 'pregnancyJourneyFeelings',
  pregnancyJourneyFile: 'pregnancyJourneyFile',
  pregnancyJourneyFiles: 'pregnancyJourneyFiles',
  pregnancyJourneyIntentions: 'pregnancyJourneyIntentions',
  pregnancyJourneyMenstrualPeriodDate: 'pregnancyJourneyMenstrualPeriodDate',
  pregnancyJourneyMiscarriageEnd: 'pregnancyJourneyMiscarriageEnd',
  pregnancyJourneyOtherEnd: 'pregnancyJourneyOtherEnd',
  pregnancyJourneyTerminationEnd: 'pregnancyJourneyTerminationEnd',
  pregnancyJourneyVaginalBirthEnd: 'pregnancyJourneyVaginalBirthEnd',
  pregnancyJourneyWeekByDeliveryDate: 'pregnancyJourneyWeekByDeliveryDate',
  pregnancyJourneyWeekByMenstrualPeriod:
    'pregnancyJourneyWeekByMenstrualPeriod',
  questionnaire: 'questionnaire',
  questionnaires: 'questionnaires',
  requestMedicalRecords: 'requestMedicalRecords',
  rescheduleAppointmentForFacility: 'rescheduleAppointmentForFacility',
  restingHeartRate: 'restingHeartRate',
  screeningConsultation: 'screeningConsultation',
  setDefaultPatientPage: 'setDefaultPatientPage',
  settings: 'settings',
  shareMaterials: 'shareMaterials',
  sharedMaterials: 'sharedMaterials',
  sharedMaterialsContents: 'sharedMaterialsContents',
  sharingLink: 'sharingLink',
  sharingLinkAggregatedActivities: 'sharingLinkAggregatedActivities',
  sharingLinkBasicHealthInfo: 'sharingLinkBasicHealthInfo',
  sharingLinkCriticalNote: 'sharingLinkCriticalNote',
  sharingLinkLatestActivities: 'sharingLinkLatestActivities',
  sharingLinkMeasurements: 'sharingLinkMeasurements',
  sharingLinks: 'sharingLinks',
  sharingLinkValidation: 'sharingLinkValidation',
  staticFile: 'staticFile',
  surgicalJourneyWeekByDate: 'surgicalJourneyWeekByDate',
  surgicalJourneyExercises: 'surgicalJourneyExercises',
  surgeryJourneyDate: 'surgeryJourneyDate',
  surgeryJourneyEnd: 'surgeryJourneyEnd',
  surveyQuestionnaire: 'surveyQuestionnaire',
  surveyQuestionnaireResponse: 'surveyQuestionnaireResponse',
  triageQuestionnaire: 'triageQuestionnaire',
  uploadSingleAppointmentFile: 'uploadSingleAppointmentFile',
  vaccinationCertUpload: 'vaccinationCertUpload',
  vaccines: 'vaccines',
  vitalConfig: 'vitalConfig',
  vitalProviders: 'vitalProviders',
  vitalProviderConnection: 'vitalProviderConnection',
  vitalProviderConnections: 'vitalProviderConnections',
  waterConsumption: 'waterConsumption',
}
