import {AssertTypeFn} from './general'
import {addressNormaliser} from './myProfile'
import {
  PermissionCategory,
  Patient,
  PatientAccountStatus,
  PractitionersPatient,
  Sex,
  Specialization,
  UnitSystem,
  SumsubAccessToken,
  IdentityDocuments,
  PatientShort,
  SubscriptionStatus,
  FacilityDetails,
  PatientOnboarding,
  PatientOnboardingStepStatus,
  OnboardingStep,
  PatientNameAndDateOfBirth,
} from '../models'
import {
  assertArray,
  assertArrayOrNull,
  assertBoolean,
  assertEnum,
  assertEnumOrNull,
  assertObjectOrUndefined,
  assertString,
  assertStringOrNull,
  getAssertEnum,
} from '../utils'

export const patientShortNormaliser: AssertTypeFn<PatientShort> = (obj) => ({
  id: assertString(obj.id),
  firstName: assertStringOrNull(obj.firstName),
  secondName: assertStringOrNull(obj.secondName),
  knownBy: assertStringOrNull(obj.knownBy),
  dateOfBirth: assertStringOrNull(obj.dateOfBirth),
  gender: assertEnumOrNull(obj.gender, Sex),
})

export const patientNormaliser: AssertTypeFn<Patient> = (obj) => ({
  completedOnboardingSteps: assertArrayOrNull(
    obj.completedOnboardingSteps,
    assertString,
  ),
  dateOfBirth: assertStringOrNull(obj.dateOfBirth),
  email: assertString(obj.email),
  firstName: assertStringOrNull(obj.firstName),
  gender: assertEnumOrNull(obj.gender, Sex),
  id: assertString(obj.id),
  isOnboardingCompleted: assertBoolean(obj.isOnboardingCompleted),
  kycMessage: assertStringOrNull(obj.kycMessage),
  knownBy: assertStringOrNull(obj.knownBy),
  phoneNumber: assertStringOrNull(obj.phoneNumber),
  secondName: assertStringOrNull(obj.secondName),
  status: assertEnumOrNull(obj.status, PatientAccountStatus),
  unitSystem: assertEnum(obj.unitSystem, UnitSystem),
  subscriptionStatus: assertEnum(obj.subscriptionStatus, SubscriptionStatus),
  idDocumentOrigin: assertStringOrNull(obj.idDocumentOrigin),
})

export const practitionersPatientNormaliser: AssertTypeFn<
  PractitionersPatient
> = (obj) => ({
  address: assertObjectOrUndefined(obj.address, addressNormaliser),
  dateOfBirth: assertStringOrNull(obj.dateOfBirth),
  facilityId: assertString(obj.facilityId),
  firstName: assertString(obj.firstName),
  gender: assertEnumOrNull(obj.gender, Sex),
  knownBy: assertStringOrNull(obj.knownBy),
  lastConsultationDate: assertStringOrNull(obj.lastConsultationDate),
  patientId: assertString(obj.patientId),
  permissions: assertArray(obj.permissions, getAssertEnum(PermissionCategory)),
  requestedPermissions: assertArray(
    obj.requestedPermissions,
    getAssertEnum(PermissionCategory),
  ),
  secondName: assertString(obj.secondName),
  specializations: assertArray(
    obj.specializations,
    getAssertEnum(Specialization),
  ),
})

export const patientNameAndDateOfBirthNormaliser: AssertTypeFn<
  PatientNameAndDateOfBirth
> = (obj) => ({
  dateOfBirth: assertStringOrNull(obj.dateOfBirth),
  firstName: assertStringOrNull(obj.firstName),
  secondName: assertStringOrNull(obj.secondName),
})

export const sumsubAccessTokenNormaliser: AssertTypeFn<SumsubAccessToken> = (
  obj,
) => ({
  token: assertString(obj.token),
  userId: assertString(obj.userId),
})

export const identityDocumentsNormaliser: AssertTypeFn<IdentityDocuments> = (
  obj,
) => ({
  idImageUrls: assertArrayOrNull(obj.idImageUrls, assertString),
})

export const facilityDetailsNormaliser: AssertTypeFn<FacilityDetails> = (
  obj,
) => ({
  documentTypes: assertArray(obj.documentTypes, assertString),
  departments: assertArray(obj.departments, assertString),
})

export const patientOnboardingNormaliser: AssertTypeFn<PatientOnboarding> = (
  obj,
) => ({
  isFullyCompleted: assertStringOrNull(obj.finishedAt),
  steps: assertArray(obj.steps, (step) => ({
    name: assertEnum(step.name, OnboardingStep),
    status: assertEnum(step.status, PatientOnboardingStepStatus),
  })),
})
