import React, {createContext, useContext, ReactNode, useMemo} from 'react'
import {useMsal} from '@azure/msal-react'

import {useGetFeatureFlags} from '~/api/featureFlags'

const ONE_HOUR = 1000 * 60 * 60

const FeatureFlagsDefaults: Record<string, boolean> = {
  preventiveMeasuresEnabled: false,
}

type FeatureFlags = typeof FeatureFlagsDefaults

type FeatureFlagContextProps = {
  featureFlags: FeatureFlags
}

const FeatureFlagContext = createContext<FeatureFlagContextProps | undefined>(
  undefined,
)

type FeatureFlagProviderProps = {
  children: ReactNode
}

export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({
  children,
}) => {
  const {accounts} = useMsal()
  const isAuthenticated = accounts.length > 0

  const {data} = useGetFeatureFlags({
    enabled: isAuthenticated,
    refetchInterval: ONE_HOUR,
  })

  const contextValue = useMemo(
    () => ({
      featureFlags:
        data?.reduce(
          (acc, flag) => {
            if (flag.name in FeatureFlagsDefaults) {
              acc[flag.name] = flag.enabled as boolean
            }
            return acc
          },
          {...FeatureFlagsDefaults} as FeatureFlags,
        ) ?? FeatureFlagsDefaults,
    }),
    [data],
  )

  return (
    <FeatureFlagContext.Provider value={contextValue}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagContext)
  if (!context) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider')
  }

  return context.featureFlags
}
