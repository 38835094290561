import React, {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {FacilityFeature} from '@common/models'

import {PractitionerContext} from '../profile/PractitionerContext'
import {LoadingPage} from './LoadingPage'

export const InitialPageRouter: React.FC = () => {
  const navigate = useNavigate()

  const {selectedEmployment} = useContext(PractitionerContext)

  useEffect(() => {
    const route = selectedEmployment?.features?.includes(
      FacilityFeature.SCREENING,
    )
      ? 'appointments'
      : 'patients'

    navigate(route)
  }, [navigate, selectedEmployment])

  return <LoadingPage />
}
