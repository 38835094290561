/* tslint:disable */
/* eslint-disable */
/**
 * Cushla API
 * REST API for Cushla
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export enum JourneyGoalStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESSFULLY_COMPLETED = 'SUCCESSFULLY_COMPLETED',
  PARTIALLY_COMPLETED = 'PARTIALLY_COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}
