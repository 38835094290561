import React from 'react'

import {StyledContactCard} from './ContactCard.styled'

type ContactCardProps = {
  children: React.ReactNode[]
}

export const ContactCard: React.FC<ContactCardProps> = ({children}) => (
  <StyledContactCard>{children}</StyledContactCard>
)
