import React from 'react'

import {Icon, IconName} from '../icon/Icon'
import {StyledContactLink} from './ContactCard.styled'
import {Typography} from '../typography/Typography'

type ContactLinkProps = {
  contactType: 'email' | 'phone'
  contactDetail: string
}

export const ContactLink: React.FC<ContactLinkProps> = ({
  contactType,
  contactDetail,
}) => {
  const [iconName, url]: [IconName, string] =
    contactType === 'email'
      ? ['Email', `mailto:${contactDetail}`]
      : ['Phone', `tel:${contactDetail}`]
  return (
    <StyledContactLink href={url}>
      <Typography textAlign="right">{contactDetail}</Typography>
      <Icon name={iconName} circled="sm" />
    </StyledContactLink>
  )
}
