import React, {useContext} from 'react'
import {Link, useMatch} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import {FacilityFeature} from '@common/models'

import {Pusher, StyledSideNav, SideNavItem} from './SideNav.styled'

import {Avatar} from '~/components/general/avatar/Avatar'
import {Icon} from '~/components/general/icon/Icon'
import {theme} from '~/styles/theme'
import {PractitionerContext} from '~/components/profile/PractitionerContext'

type SideNavProps = {
  hideButtons?: boolean
}

export const SideNav: React.FC<SideNavProps> = ({hideButtons}) => {
  const {t} = useTranslation()

  const isActive = {
    patients: !!useMatch('/app/patients/*'),
    profile: !!useMatch('/app/profile/*'),
    materials: !!useMatch('/app/materials/*'),
    questionnaires: !!useMatch('/app/questionnaires/*'),
    screening: !!useMatch('/app/appointments/*'),
  }
  const {practitioner, selectedEmployment} = useContext(PractitionerContext)

  return (
    <StyledSideNav>
      <Link to="/">
        <SideNavItem logo>
          <Icon name="Logo" size="xl" />
        </SideNavItem>
      </Link>
      {!hideButtons && (
        <>
          <Link
            to="/app/patients"
            data-tip={t('My patients')}
            data-for="sideNav">
            <SideNavItem active={isActive.patients}>
              <Icon
                name="Patients"
                color={isActive.patients ? undefined : theme.colors.grey.main}
              />
            </SideNavItem>
          </Link>
          <Link
            to="/app/materials"
            data-tip={t('Educational materials')}
            data-for="sideNav">
            <SideNavItem active={isActive.materials}>
              <Icon
                name="BookOpen"
                color={isActive.materials ? undefined : theme.colors.grey.main}
              />
            </SideNavItem>
          </Link>
          <Link
            to="/app/questionnaires"
            data-tip={t('Questionnaires')}
            data-for="sideNav">
            <SideNavItem active={isActive.questionnaires}>
              <Icon
                name="Questionnaires"
                color={
                  isActive.questionnaires ? undefined : theme.colors.grey.main
                }
              />
            </SideNavItem>
          </Link>
          {selectedEmployment?.features?.includes(
            FacilityFeature.SCREENING,
          ) && (
            <Link
              to="/app/appointments"
              data-tip={t('My Appointments')}
              data-for="sideNav">
              <SideNavItem active={isActive.questionnaires}>
                <Icon
                  name="Calendar"
                  color={
                    isActive.screening ? undefined : theme.colors.grey.main
                  }
                />
              </SideNavItem>
            </Link>
          )}
          <Pusher />
          <Link to="/app/profile" data-tip={t('Profile')} data-for="sideNav">
            <SideNavItem active={isActive.profile}>
              <Avatar size="sm" name={practitioner} />
            </SideNavItem>
          </Link>
        </>
      )}
      <ReactTooltip id="sideNav" place="right" effect="solid" />
    </StyledSideNav>
  )
}
