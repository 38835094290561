import {
  DateTime,
  DateTimeFormatOptions,
  DateTimeOptions,
  DateTimeUnit,
  DurationLikeObject,
} from 'luxon'

import {ExactOrEstimatedDate, HealthDataResolution} from '../models'
import {isTruthy} from './helpers'

export const formatToJSDate = (date: string | null | undefined) => {
  if (!date) {
    return undefined
  }
  return DateTime.fromISO(date).toJSDate()
}

export const formatDate = (
  format: DateTimeFormatOptions,
  date: string | null | undefined,
  options?: DateTimeOptions,
  defaultValue = '',
) => {
  if (!date) {
    return defaultValue
  }
  return DateTime.fromISO(date, options).toLocaleString(format)
}

export const monthYearDateFormat: DateTimeFormatOptions = {
  month: 'long',
  year: 'numeric',
}

export const formatExactOrEstimatedDate = (
  date?: ExactOrEstimatedDate | null,
  options?: DateTimeOptions,
) =>
  date?.isEstimated
    ? formatDate(monthYearDateFormat, date.date, options)
    : formatDate(DateTime.DATE_FULL, date?.date, options)

export const createExactOrEstimatedDate = (
  date?: string | null,
  isEstimated = false,
) =>
  date
    ? {
        date,
        isEstimated,
      }
    : null

const getCalendarFormat = (date: DateTime) => {
  const diff = date.diff(DateTime.now().startOf('day'), 'days').as('days')

  if (diff < -6) {
    return 'sameElse'
  }
  if (diff < -1) {
    return 'lastWeek'
  }
  if (diff < 0) {
    return 'lastDay'
  }
  if (diff < 1) {
    return 'sameDay'
  }
  if (diff < 2) {
    return 'nextDay'
  }
  if (diff < 7) {
    return 'nextWeek'
  }
  return 'sameElse'
}

const calendarDateFormats = {
  sameDay: "'Today at' t",
  nextDay: "'Tomorrow at' t",
  nextWeek: "EEEE 'at' t",
  lastDay: "'Yesterday at' t",
  lastWeek: "'Last' EEEE 'at' t",
}

export const getCalendarDateTime = (
  date?: string | null,
  setZone?: boolean,
) => {
  if (!date) {
    return ''
  }

  const parsedDate = DateTime.fromISO(date, {
    setZone,
  })
  const format = getCalendarFormat(parsedDate)

  if (format === 'sameElse') {
    return parsedDate.toLocaleString(DateTime.DATETIME_MED)
  }
  return parsedDate.toFormat(calendarDateFormats[format])
}

export const resolutionUnitMap: Record<HealthDataResolution, DateTimeUnit> = {
  [HealthDataResolution.MONTH]: 'month',
  [HealthDataResolution.WEEK]: 'week',
  [HealthDataResolution.DAY]: 'day',
}

export const getResolutionDateDiff: (
  resolution: HealthDataResolution,
  intervalCount?: number,
) => DurationLikeObject = (resolution, intervalCount = 1) => ({
  [resolutionUnitMap[resolution]]: intervalCount,
})

export const getHealthDataIntervalEnd = (
  startDate: DateTime,
  resolution: HealthDataResolution,
  intervalCount?: number,
) =>
  startDate
    .plus(getResolutionDateDiff(resolution, intervalCount))
    .minus({day: 1})

export const getAlignedHealthDataIntervalStart = (
  endDate: DateTime,
  resolution: HealthDataResolution,
  intervalCount = 1,
) =>
  endDate
    .startOf(resolutionUnitMap[resolution])
    .minus(getResolutionDateDiff(resolution, intervalCount - 1)) // we substract 1, because one (incomplete) interval has already been substracted by startOf()

export const formatDateInterval = (
  startDate: DateTime,
  endDate: DateTime,
  dateFormat: Intl.DateTimeFormatOptions = DateTime.DATE_SHORT,
) => {
  if (startDate.startOf('day').toISO() === endDate.startOf('day').toISO()) {
    return startDate.toLocaleString(dateFormat)
  }

  return `${startDate.toLocaleString(dateFormat)} - ${endDate.toLocaleString(
    dateFormat,
  )}`
}

export const formatHealthDataDatePeriod = (
  startOfPeriod: string,
  resolution?: HealthDataResolution | null,
) => {
  if (!resolution || resolution === HealthDataResolution.DAY) {
    return formatDate(DateTime.DATE_SHORT, startOfPeriod)
  }

  const startDate = DateTime.fromISO(startOfPeriod)
  const endDate = getHealthDataIntervalEnd(startDate, resolution)

  return formatDateInterval(startDate, endDate)
}

export const getMinDate = (dates: (Date | null | undefined)[]) => {
  const filteredDates = dates.filter(isTruthy)

  if (!filteredDates.length) {
    return undefined
  }

  return filteredDates.reduce((minDate, currentDate) =>
    minDate < currentDate ? minDate : currentDate,
  )
}

export const getMaxDate = (dates: (Date | null | undefined)[]) => {
  const filteredDates = dates.filter(isTruthy)

  if (!filteredDates.length) {
    return undefined
  }

  return filteredDates.reduce((maxDate, currentDate) =>
    maxDate > currentDate ? maxDate : currentDate,
  )
}

export const getTodayAsString = () => DateTime.now().toISODate().split('T')[0]
