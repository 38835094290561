import {
  CSectionDeliverySummaryDto,
  GetStoppedPregnancySummaryDto,
  OtherPregnancyEndSummaryDto,
  PregnancyEndTypeDto,
  SaveStoppedPregnancySummaryDto,
  VaginalBirthSummaryDto,
} from '@generated/models'

export type {
  AddEstimatedPregnancyDeliveryDateDto as AddEstimatedDeliveryDateRequest,
  AddLastMenstrualPeriodDto as AddLastMenstrualPeriodDateRequest,
  ArthritisJourneyCloseDto as CloseArthritisJourneyRequest,
  ArthritisJourneyOverviewDto as ArthritisJourneyOverview,
  SurgeryDateDto as SurgeryDate,
  PainScoreDto as PainScore,
  PainScoreAverage,
  PainScoreOverview,
  ArthritisSymptomsDto as ArthritisSymptoms,
  ArthritisSymptomsAggregation,
  UpdateArthritisSymptomsDto as UpdateArthritisSymptoms,
  SurgeryDateDto as AddSurgeryDateRequest,
  CreateJourneyDto as AddHealthJourneyRequest,
  CreatePregnancyFamilyHistoryDto as AddPregnancyFamilyHistoryRequest,
  CreatePregnancyFeelingDto as AddPregnancyFeelingRequest,
  CreatePregnancyIntentionDto as AddPregnancyIntentionRequest,
  CreateJourneyNoteDto as AddJourneyNoteRequest,
  GetBirthPreferencesDto as PatientPregnancyBirthPreferences,
  GetEstimatedPregnancyDeliveryDateDto as EstimatedDeliveryDate,
  GetLastMenstrualPeriodDto as LastMenstrualPeriodDate,
  GetJourneyDto as PatientHealthJourney,
  GetJourneySetupStepDto as HealthJourneySteps,
  GetPregnancyFeelingDto as PatientPregnancyFeeling,
  GetPregnancyIntentionDto as PatientPregnancyIntention,
  GetJourneyNoteDto as PatientJourneyNote,
  GetJourneyNotesDto as PatientJourneyNotes,
  JourneySetupStepDto as HealthJourneyStep,
  PregnancyArticleDto as PregnancyArticle,
  PregnancyDatesDto as PregnancyDates,
  PregnancyEndChildDto as PregnancyEndChild,
  PregnancyFamilyHistoryDto as PatientPregnancyFamilyHistory,
  SaveBirthPreferencesDto as AddPregnancyBirthPreferencesRequest,
  JourneyGoalDto as HealthJourneyGoal,
  UpsertJourneyGoalDto as UpsertHealthJourneyGoalRequest,
  UpdateJourneyGoalStatusDto as UpdateHealthJourneyGoalStatusRequest,
} from '@generated/models'

export {
  BloodLossDto as BloodLossAmount,
  BirthPreferencesFeedingPlanDto as FeedingPlan,
  BirthPreferencesTypeDto as LabourType,
  BirthPreferencesVitaminKDto as VitaminKPreference,
  BirthPreferredPositionDto as LabourPosition,
  CSectionAnaesthesiaTypeDto as AnaesthesiaType,
  CSectionTypeDto as CSectionType,
  FrontendScreenName,
  JourneySetupCompletionDto as HealthJourneyStepCompletion,
  JourneyTypeDto as HealthJourneyType,
  JourneyGoalStatus as HealthJourneyGoalStatus,
  PregnancyDeliveryDateEstimateSourceDto as EstimatedDeliveryDateSource,
  PregnancyEndTypeDto as PregnancyEndType,
  PregnancyFeelingOptionDto as PregnancyFeeling,
  PregnancyFamilyHistoryConditionOptionDto as PregnancyFamilyHistoryCondition,
  PregnancyIntentionOptionDto as PregnancyIntention,
  VaginalBirthAnaesthesiaTypeDto as LabourPainRelief,
  VaginalBirthTypeDto as VaginalBirthType,
} from '@generated/models'

export type PregnancyMiscarriageEndSummary = GetStoppedPregnancySummaryDto & {
  endType: PregnancyEndTypeDto.MISCARRIAGE
}

export type PregnancyTerminationEndSummary = GetStoppedPregnancySummaryDto & {
  endType: PregnancyEndTypeDto.TERMINATION
}

export type PregnancyOtherEndSummary = OtherPregnancyEndSummaryDto & {
  endType: PregnancyEndTypeDto.OTHER
}

export type PregnancyCSectionEndSummary = CSectionDeliverySummaryDto & {
  endType: PregnancyEndTypeDto.C_SECTION
}

export type PregnancyVaginalBirthEndSummary = VaginalBirthSummaryDto & {
  endType: PregnancyEndTypeDto.VAGINAL_BIRTH
}

export type AddMiscarriageSummaryRequest = SaveStoppedPregnancySummaryDto & {
  endType: PregnancyEndTypeDto.MISCARRIAGE
}

export type AddTerminationSummaryRequest = SaveStoppedPregnancySummaryDto & {
  endType: PregnancyEndTypeDto.TERMINATION
}

export type PregnancyEndSummary =
  | PregnancyCSectionEndSummary
  | PregnancyVaginalBirthEndSummary
  | PregnancyOtherEndSummary
  | PregnancyTerminationEndSummary
  | PregnancyMiscarriageEndSummary
