import * as React from 'react'

import {IconProps} from '.'

function Phone({color, size, isFilled}: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={isFilled ? color : 'none'}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7999 21.0002C16.4999 21.0002 16.2999 21.0002 15.9999 20.9002C13.0999 20.4002 10.0999 18.7002 7.69994 16.2002C5.29994 13.8002 3.59994 10.9002 2.99994 8.0002C2.79994 6.7002 3.19994 5.3002 4.09994 4.4002L4.79994 3.7002C5.79994 2.7002 7.59994 2.7002 8.69994 3.7002L9.79994 5.0002C10.1999 5.4002 10.4999 6.0002 10.3999 6.6002C10.3999 7.2002 10.0999 7.8002 9.69994 8.2002L8.89994 9.0002C9.49994 10.3002 10.2999 11.5002 11.3999 12.6002C12.4999 13.7002 13.6999 14.5002 14.9999 15.1002L15.8999 14.2002C16.6999 13.3002 18.1999 13.3002 19.0999 14.2002L20.2999 15.4002C21.3999 16.5002 21.3999 18.2002 20.2999 19.3002L19.5999 20.0002C18.7999 20.6002 17.7999 21.0002 16.7999 21.0002ZM6.69994 4.5002C6.39994 4.5002 6.09994 4.6002 5.79994 4.9002L5.19994 5.5002C4.59994 6.0002 4.39994 6.9002 4.49994 7.7002C4.99994 10.3002 6.49994 13.0002 8.79994 15.2002C10.9999 17.4002 13.6999 19.0002 16.2999 19.5002C17.0999 19.7002 17.8999 19.4002 18.4999 18.9002L19.1999 18.2002C19.6999 17.7002 19.6999 16.9002 19.1999 16.4002L17.9999 15.2002C17.6999 14.9002 17.1999 14.9002 16.8999 15.2002L15.9999 16.1002C15.5999 16.5002 14.8999 16.7002 14.2999 16.4002C12.7999 15.8002 11.4999 14.8002 10.2999 13.6002C9.09994 12.4002 8.09994 11.0002 7.49994 9.60019C7.29994 9.10019 7.39994 8.4002 7.89994 8.0002L8.69994 7.2002C8.89994 7.0002 8.99994 6.8002 8.99994 6.5002C8.99994 6.3002 8.89994 6.2002 8.79994 6.0002L7.59994 4.8002C7.29994 4.6002 6.99994 4.5002 6.69994 4.5002Z"
        fill={color}
      />
    </svg>
  )
}

export default Phone
