import React, {useEffect} from 'react'
import {Navigate, Routes, Route, Outlet} from 'react-router-dom'
import {useIsAuthenticated} from '@azure/msal-react'
import {Settings} from 'luxon'
import {useTranslation} from 'react-i18next'

import {FeatureFlagProvider} from './components/general/FeatureFlagContext'
import {PreviousPreganciesPage} from './components/patients/previousPregnancies/PreviousPregnanciesPage'
import {LoginPage} from './components/website/Login'
import {PatientsPage} from './components/patients/PatientsPage'
import {ProfilePage} from './components/profile/ProfilePage'
import {MaterialsConditionPage} from './components/educationalMaterials/pages/MaterialsConditionPage'
import {MaterialsSubcategoryPage} from './components/educationalMaterials/pages/MaterialsSubcategoryPage'
import {ProtectedPageTemplate} from './components/website/ProtectedPageTemplate'
import {MaterialViewerPage} from './components/educationalMaterials/pages/MaterialViewerPage'
import {PatientDetail} from './components/patients/PatientDetail'
import {PersonalDetail} from './components/patients/personalDetail/PersonalDetail'
import {PatientSharedMaterials} from './components/patients/sharedMaterials/PatientSharedMaterials'
import {PatientTimelinePage} from './components/patients/timeline/PatientTimelinePage'
import {BasicHealthInformation} from './components/patients/basicHealthInformation/BasicHealthInformation'
import {
  AllergiesPage,
  CholesterolPage,
  ConditionsPage,
  MedicalTestsPage,
  MedicationsPage,
  SurgeriesPage,
  VaccinesPage,
} from './components/patients/basicHealthInformation/HealthCategoriesPages'
import {LifestyleInformationPage} from './components/patients/basicHealthInformation/LifestyleInformationPage'
import {PersonalInformationPage} from './components/patients/personal/PersonalInformationPage'
import {QuestionnairesPage} from './components/questionnaires/pages/QuestionnairesPage'
import {QuestionnairesLibrary} from './components/questionnaires/pages/QuestionnairesLibrary'
import {Questionnaire} from './components/questionnaires/pages/Questionnaire'
import {QuestionnaireDetail} from './components/questionnaires/QuestionnaireDetail'
import {SentQuestionnaires} from './components/questionnaires/pages/SentQuestionnaires'
import {QuestionnaireResponses} from './components/questionnaires/pages/QuestionnaireReponses'
import {UnansweredQuestionnaires} from './components/questionnaires/pages/UnansweredQuestionnaires'
import {QuestionnaireResponse} from './components/questionnaires/pages/QuestionnaireResponse'
import {PatientQuestionnaires} from './components/patients/questionnaires/PatientQuestionnaires'
import {PatientQuestionnaire} from './components/patients/questionnaires/PatientQuestionnaire'
import {PatientQuestionnaireResponse} from './components/patients/questionnaires/PatientQuestionnaireResponse'
import {PatientQuestionnaireHistory} from './components/patients/questionnaires/PatientQuestionnaireHistory'
import {PatientDataWrapper} from './components/patients/PatientDataWrapper'
import {PatientDashboard} from './components/patients/dashboard/PatientDashboard'
import {LoginRedirect} from './components/website/LoginRedirect'
import {FamilyHistoryPage} from './components/patients/basicHealthInformation/FamilyHistoryPage'
import {LinkSharingPage} from './components/linkSharing/LinkSharingPage'
import {NotFoundPage} from './components/website/NotFoundPage'
import {PatientPhysitrackPrograms} from './components/patients/physitrack/PatientPhysitrackPrograms'
import {LifeMilestonesPage} from './components/patients/personal/LifeMilestonesPage'
import {MaterialPreviewPage} from './components/educationalMaterials/pages/MaterialPreviewPage'
import {TrendsPage} from './components/patients/trends/TrendsPage'
import {AppointmentsPage} from './components/patients/appointments/AppointmentsPage'
import {AppointmentDetailPage} from './components/patients/appointments/AppointmentDetailPage'
import {DefaultPageRoute} from './components/general/defaultPageRoute/DefaultPageRoute'
import {PractitionerAppointmentsPage} from './components/facility/PractitionerAppointmentsPage'
// import AddScreeningDataPage from './components/facility/AddScreeningDataPage'
import TempScreeningPage from './components/facility/screening/tempPage/TempScreeningPage'
import {InitialPageRouter} from './components/website/InitialPageRouter'
import {PreventivePage} from './components/patients/preventive/PreventivePage'

import {browserLanguage} from '~/utils'

export const App: React.FC = () => {
  const isLoggedIn = useIsAuthenticated()
  const {i18n} = useTranslation()

  useEffect(() => {
    Settings.defaultLocale =
      browserLanguage === i18n.resolvedLanguage
        ? window.navigator.language
        : i18n.resolvedLanguage
  }, [i18n.resolvedLanguage])

  return (
    <FeatureFlagProvider>
      <Routes>
        <Route
          path="/"
          element={isLoggedIn ? <Navigate replace to="/app" /> : <LoginPage />}
        />
        <Route
          path="/login-redirect"
          element={
            isLoggedIn ? <Navigate replace to="/app" /> : <LoginRedirect />
          }
        />
        <Route path="/medical-record-share" element={<LinkSharingPage />} />
        <Route path="/app" element={<ProtectedPageTemplate />}>
          <Route index element={<InitialPageRouter />} />
          <Route path="patients" element={<PatientsPage />} />
          <Route path="patients/:patientId" element={<PatientDataWrapper />}>
            <Route
              path="*"
              element={<PatientDetail fullPath="app/patients/:id/" />}>
              <Route index element={<DefaultPageRoute />} />
              <Route path="*" element={<Navigate replace to="dashboard" />} />
              <Route path="dashboard" element={<PatientDashboard />} />
              <Route path="timeline" element={<PatientTimelinePage />} />
              <Route
                path="journeys/previous-pregnancies"
                element={<PreviousPreganciesPage showReturnLink />}
              />
              <Route path="trends" element={<TrendsPage />} />
              <Route path="appointments" element={<AppointmentsPage />} />
              <Route
                path="appointments/:appointmentId"
                element={<AppointmentDetailPage />}
              />
              <Route path="medical" element={<Outlet />}>
                <Route path="*" element={<Navigate replace to="" />} />
                <Route index element={<BasicHealthInformation />} />
                <Route path="conditions" element={<ConditionsPage />} />
                <Route path="allergies" element={<AllergiesPage />} />
                <Route path="tests" element={<MedicalTestsPage />} />
                <Route path="cholesterol" element={<CholesterolPage />} />
                <Route path="family-history" element={<FamilyHistoryPage />} />
                <Route path="medications" element={<MedicationsPage />} />
                <Route path="vaccinations" element={<VaccinesPage />} />
                <Route path="surgeries" element={<SurgeriesPage />} />
                <Route path="obstetrics" element={<PreviousPreganciesPage />} />
              </Route>
              <Route path="lifestyle" element={<LifestyleInformationPage />} />
              <Route path="preventive" element={<PreventivePage />} />
              <Route path="personal" element={<PersonalInformationPage />} />
              <Route path="profile" element={<PersonalDetail />} />
              <Route path="milestones" element={<LifeMilestonesPage />} />
              <Route
                path="questionnaires"
                element={<PatientQuestionnaires />}
              />
              <Route path="materials" element={<PatientSharedMaterials />} />
              <Route
                path="physitrack"
                element={<PatientPhysitrackPrograms />}
              />
            </Route>
            <Route
              path="questionnaires/:assignedQuestionnaireId"
              element={<PatientQuestionnaire />}>
              <Route index element={<Navigate replace to="details" />} />
              <Route path="*" element={<Navigate replace to="details" />} />
              <Route
                path="details"
                element={<PatientQuestionnaireResponse />}
              />
              <Route path="history" element={<PatientQuestionnaireHistory />} />
            </Route>
          </Route>
          <Route
            path="questionnaires"
            element={<QuestionnairesPage fullPath="app/questionnaires/" />}>
            <Route index element={<Navigate replace to="responses" />} />
            <Route path="*" element={<Navigate replace to="responses" />} />
            <Route path="responses" element={<QuestionnaireResponses />} />
            <Route
              path="responses/:assignedQuestionnaireId"
              element={<QuestionnaireResponse />}
            />
            <Route path="unanswered" element={<UnansweredQuestionnaires />} />
            <Route path="library" element={<QuestionnairesLibrary />} />
          </Route>
          <Route
            path="questionnaires/:questionnaireId"
            element={<Questionnaire />}>
            <Route index element={<Navigate replace to="details" />} />
            <Route path="*" element={<Navigate replace to="details" />} />
            <Route path="details" element={<QuestionnaireDetail />} />
            <Route path="sent" element={<SentQuestionnaires />} />
          </Route>
          <Route
            path="appointments"
            element={<PractitionerAppointmentsPage />}
          />
          <Route
            path="appointments/:appointmentId/:patientId"
            element={<PatientDataWrapper />}>
            {/* <Route index element={<AddScreeningDataPage />} /> */}
            <Route index element={<TempScreeningPage />} />
          </Route>
          <Route path="materials" element={<MaterialsConditionPage />} />
          <Route path="materials/preview" element={<MaterialPreviewPage />} />
          <Route
            path="materials/file/:materialId"
            element={<MaterialViewerPage />}
          />
          <Route
            path="materials/:conditionId"
            element={<MaterialsConditionPage />}
          />
          <Route
            path="materials/:conditionId/:id/*"
            element={<MaterialsSubcategoryPage />}
          />
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </FeatureFlagProvider>
  )
}
