export enum BodyLocation {
  ABDOMINAL = '0',
  CHEST = '1',
  EXTREMITIES = '2',
  HEAD = '3',
  LIFESTYLE = '4',
  MENTAL_HEALTH = '5',
  NECK = '6',
  PELVIC = '7',
}

export type PreventiveActivity = {
  preventiveActivityId: string
  description: string
  physicianNotification: string
  bodyLocation: string
  alertCreationDate: string
  reminderStatus: string
  dueDate: string
  practitionerAction?: string
}

export enum PreventiveActionType {
  DUE = 'DUE',
  COMPLETED = 'COMPLETED',
  SCHEDULED = 'SCHEDULED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PATIENT_DECLINED = 'PATIENT_DECLINED',
}

export type PreventiveActivityAction = {
  action: PreventiveActionType
  date: string
}
