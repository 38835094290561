import styled from 'styled-components'

export const StyledContactCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({theme}) => theme.spacing.xs};
  border-radius: ${({theme}) => theme.borderRadius.sm};
  background-color: ${({theme}) => theme.colors.white};
  margin-left: auto;
  gap: ${({theme}) => theme.spacing.xs};
`

export const StyledContactLink = styled.a`
  display: flex;
  font-size: ${({theme}) => theme.fontSize.md};
  padding: 0 ${({theme}) => theme.spacing.lg};
  margin-left: auto;
  justify-content: right;
  align-items: center;
  gap: ${({theme}) => theme.spacing.xs};

  &:hover {
    text-decoration: underline;
    background-color: ${({theme}) => theme.colors.grey.grey100};
    border-radius: ${({theme}) => theme.borderRadius.sm};
  }
`
