import styled from 'styled-components'

import {Container} from '~/components/general/container/Container'

export const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const ActionContainer = styled(FlexContainer)`
  margin-top: 1rem;
`

export const ModalContent = styled.div`
  height: 100%;
  overflow: auto;
  padding: 1rem;
`

export const ModalFooter = styled.div`
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid ${(props) => props.theme.colors.inputBorder};
`

export const FacilityContainer = styled.div`
  flex: 1;
  margin: 1rem 0;
`

export const TextAreaContainer = styled(Container)`
  margin: 1rem 0;
`

export const CharacterCount = styled.span`
  color: ${(props) => props.theme.colors.grey.main};
  font-size: 0.875rem;
`

export const FacilitiesList = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
`

export const FacilityItem = styled.li`
  cursor: pointer;
  padding: 8px;
  list-style: none;
  border-bottom: 1px solid ${({theme}) => theme.colors.grey.grey100};

  &:hover {
    background-color: ${({theme}) => theme.colors.grey.grey40};
  }
`
